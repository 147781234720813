var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container pt-5"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('faq-section',{attrs:{"faqs":_vm.faqs}}),_c('contact-anchor')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header clear-filter",attrs:{"filter-color":"greenTogreen"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"photo-container"},[_c('img',{attrs:{"src":"img/imago.png","alt":""}})]),_c('h1',{staticClass:"title text-black"},[_vm._v("Servicio de Patrocinadores")]),_c('h2',{staticClass:"text-black-50"},[_vm._v("¡Tus patrocinadores te recibirán con los brazos abiertos!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-container"},[_c('a',{staticClass:"btn btn-secondary btn-round btn-xl",attrs:{"href":"https://app.cluber.es/register"}},[_vm._v("Probar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-auto col-md-6 col-xl-12"},[_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Las miles de visitas de tu club en Siguetuliga le interesan a los anunciantes.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Al fin tienes algo que ofrecerles para pedir su colaboración.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Además de asociar su imagen a tu club, les puedes mandar clientes a su web")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-center m-5"},[_c('iframe',{staticClass:"page-header-image mt-5",attrs:{"title":"vimeo-player","src":"https://player.vimeo.com/video/365585893","width":"640","height":"360","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("¿Cómo funciona?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap mb-5"},[_c('ol',{staticClass:"col-md-12"},[_c('li',[_vm._v("Siguetuliga es una red social con más de 3 millones de usuarios. Eso genera miles de visitas en las páginas de tu club.")]),_c('li',[_vm._v("Allí donde aparece tu club puedes poner a tus patrocinadores y cobrarles lo que estimes oportuno. No hay límite de patrocinadores, puedes poner los que quieras y se irán rotando. ")]),_c('li',[_vm._v("Desde la imagen que pongas puedes situar también un enlace para que, al pinchar, el usuario vaya a la web del anunciante, su Facebook…")]),_c('li',[_vm._v("El patrocinador estará encantado, ya que lo conocerá la gente que esté interesada en tu club. ¿Cuánto querrá pagar para que lo vean y visiten miles de posibles clientes?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("Desde ahora podrás...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap"},[_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Subir un número ilimitado de patrocinadores a las páginas de tu club.")]),_c('li',[_vm._v("Saber cuántas visitas tienen tus patrocinadores cada mes, para así poder informarlos.")]),_c('li',[_vm._v("Colocar un enlace en cada imagen para enviar visitas a sus webs.")])]),_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Elegir qué patrocinadores salen más veces que otros, en función de su aportación.")]),_c('li',[_vm._v("Modificar los anunciantes que quieres mostrar en cualquier momento.")]),_c('li',[_vm._v("Descargar un dosier personalizado del club con el que convencer a los patrocinadores.")])])])
}]

export { render, staticRenderFns }