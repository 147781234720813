<script>
import StoryblokClient from "storyblok-js-client";
import SpinnerLoader from '../../components/SpinnerLoader.vue'


const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default{
  data(){
    return {
      federations: [],
      loading: true,
    }
  },
  components: {
    SpinnerLoader,
  },
  mounted () {
    this.fetchData()
      .then((data) => {
        this.federations = data;
        this.loading = false;
    })
  },
  methods: {
    async fetchData () {
      const federations = await api.get('cdn/stories', {
        starts_with: 'surfcollection',
        content_type: 'federationCollection',
        sort_by: 'content.order:asc'
      })

      return federations.data.stories
    },
    handleNavigation(federation){
      if(federation.content.accessPurchableItem){
        window.location.href = federation.content.licenses_url
      } else {
        this.$router.push({ name: 'surf-portal', params: { federationId: federation.content.id } })
      }
    }
  }

}
</script>
<template>
  <div class="container d-flex flex-column align-items-center my-5">
    <div class="h4 mt-2 w-100">Inicio</div>
    <i class="fas fa-arrow-left" aria-hidden="true"></i>
    <h1 class="font-weight-bold">Licencias Surf Temporada 2024</h1>
    <h5 class="font-weight-bold mt-3 text-md-center text-primary">SI PERTENECES A ALGUNA DE LAS SIGUIENTES COMUNIDADES AUTÓNOMAS DEBES FEDERARTE A TRAVÉS DE LA FEDERACIÓN AUTONÓMICA CORRESPONDIENTE<br/>EN CASO CONTRARIO, PUEDES HACERLO DIRECTAMENTE A TRAVÉS DE LA <a :href="federations[0].content.licenses_url">FEDERACIÓN ESPAÑOLA</a></h5>
    <div class="row w-100 justify-content-center">
      <SpinnerLoader v-if="loading"></SpinnerLoader>
      <div
        class="col-md-3 my-1 d-flex flex-column align-items-center px-3"
        :key="federation.id"
        v-for="federation in federations"
      >

        <button
        type="button"
        @click="handleNavigation(federation)"
          class="btn-link no-underline"
        >
          <div class="p-3 border rounded border-dark m-4">
            <img :src="federation.content.logo.filename"/>
          </div>
          <span class="text-center text-dark d-block font-weight-bold">{{ federation.content.name }}</span>
        </button>
      </div>
    </div>
    <p class="font-weight-bold mt-4">¿Alguna duda?</p>
    <ul class="pl-0">
      <li>Correo electrónico: <a href="mailto:administracion@fesurf.es">administracion@fesurf.es</a></li>
      <li>Teléfono: <a href="tel:+34981311666">981 311 666</a></li>
    </ul>
  </div>
</template>