<script>
import LandingPage from '@/mixins/LandingPage';

export default {
  name: 'sponsorshipbar',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
  },
  mixins: [
    LandingPage
  ],
  data() {
    return {
    };
  },
};
</script>

<template>
  <div
    :class="[
      'align-items-center bg-secondary justify-content-center p-3 px-lg-3 p-lg-0 mx-auto flex-wrap flex-lg-nowrap d-flex',
      fullWidth ? 'col-12' : 'col-11 col-md-12 col-lg-11 rounded-lg'
    ]"
  >
    <span class="rounded-lg bg-danger p-2 mb-3 mb-md-0 text-bold d-none d-md-block">¡NUEVO!</span>
    <span class="rounded-lg bg-danger p-2 m-0 text-bold d-flex d-md-none rounded-circle alert-mobile justify-content-center align-items-center">!</span>
    <!-- Desktop text -->
    <p class="ml-0 ml-md-4 mb-0 mr-3 mr-md-0 text-bold text-center w-md-auto m-md-0 d-none d-md-block">
      Actualmente estamos patrocinando clubes deportivos ¿Te interesa?
    </p>
    <!-- Mobile text -->
    <p class="ml-1 ml-md-4 mb-0 mr-3 mr-md-0 text-bold text-left text-md-center w-md-auto m-md-0 d-block d-md-none">
      Patrocinio clubes deportivos
    </p>
    <a
      :href="isEnclosedLandingPage ? this.$route.query.pipedriveForm : 'http://sponsorship.cluber.es'"
      class="btn btn-primary btn-sm ml-0 ml-md-3 col-2 col-md-1 rounded-pill btn--width"
    >
      Solicitar
    </a>
    <a
      v-if="!isEnclosedLandingPage"
      href="https://cluber.es/blog/patrocinio-clubes"
      class="btn btn-tertiary btn-sm ml-0 ml-md-3 col-2 col-md-1 rounded-pill btn--width"
    >
      Más info
    </a>
  </div>
</template>

<style scoped>
.btn--width {
  min-width: 120px;
}

.alert-mobile {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
</style>