<template>
    <div>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <h1 class="title text-info text-left text-underline mb-4">Cluber Administraciones</h1>
                <h2 class="text-black-50 text-left">Agilizamos y estandarizamos los trámites administrativos,
                para conseguir un funcionamiento más eficaz de todas las entidades</h2>
            </div>
            <div class="container flex-column align-items-center p-0">
                <img
                    src="/img/office2.jpg"
                    alt="Oficina digitalizada"
                >
            </div>
        </div>
        <section class="container mt-5">
            <div class="d-flex flex-column">
                <h2 class="title text-underline">¿Cómo funciona para la administración?</h2>
                <ol class="text-large">
                    <li>Cluber integra los pliegos de la subvención que concede la administración.</li>

                    <li>Los clubes inician <strong>proceso de solicitud y/o justificación guiados paso por paso</strong>, evitando errores, defectos de forma o de tiempo.</li>

                    <li>La administración ve en todo momento el estado de la solicitud o de la justificación. En cualquier momento, <strong>puede contactar con cualquier club a través de la aplicación.</strong></li>

                    <li>El club finaliza el proceso firmando la documentación en Cluber, quedando disponible para la entrega.</li>
                </ol>
            </div>
        </section>
        <section class="container mt-5">
            <div class="d-flex flex-column">
                <h2 class="title text-underline">¿Cómo funciona para los clubes?</h2>
                <p class="text-large">Además de gestionar las subvenciones de forma rápida y sencilla, los clubes pueden beneficiarse de
                    muchas utilidades. Con Cluber pueden renovar y hacer nuevos socios de forma online, gestionar
                    automáticamente el cobro de las cuotas de los niños, vender entradas o material, aumentar sus
                    ingresos por patrocinadores, comunicarse internamente con su masa social... En definitiva,
                    digitalizarse para ser mucho más eficaces.</p>
            </div>
        </section>
        <section class="container mt-5 pt-5">
            <h2 class="title title-up text-center mb-5">Cluber beneficia a todos</h2>
            <div class="d-flex flex-row flex-wrap">
                <div class="d-flex flex-column">
                    <img class="fer w-50 mt-3 mb-3 ml-auto mr-auto" src="img/vector/stl_sign_indigo.svg" alt="Administration sign on paper">
                    <h3 class="text-center subtitle mb-5">Administración</h3>
                    <ul class="text-normal">
                        <li class="text-bold">Abandera la digitalización en el deporte</li>
                        <li>
                            <span class="text-bold">Refuerza el vínculo administración-club</span>
                            <ul>
                                <li>Clubes satisfechos</li>
                                <li>Masas sociales satisfechas</li>
                            </ul>
                        </li>
                        <li>
                            <span class="text-bold">Gestiona subvenciones de forma óptima</span>
                            <ul>
                                <li>Datos centralizados y sin errores</li>
                                <li>Ahorro de tareas administrativas</li>
                                <li>Transparencia</li>
                                <li>Canal de comunicación</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="d-flex flex-column">
                    <img class="fer w-50  mt-3 mb-3 ml-auto mr-auto" src="img/vector/stl_manager_cyan.svg" alt="Club manager with a scarf">
                    <h3 class="text-center subtitle mb-5">Clubes</h3>
                    <ul class="text-normal">
                        <li>
                            <span class="text-bold">Aumenta sus ingresos</span>
                            <ul>
                                <li>Socios</li>
                                <li>Venta de entradas y material</li>
                                <li>Patrocinadores</li>
                            </ul>
                        </li>
                        <li>
                            <span class="text-bold">Realiza inscripciones y pagos online</span>
                            <ul>
                                <li>Pagos con tarjeta o domiciliación</li>
                                <li>Sin gestión manual de remesas</li>
                            </ul>
                        </li>
                        <li>
                            <span class="text-bold">Cumple con la ley RGPD</span>
                        </li>
                    </ul>
                </div>
                <div class="d-flex flex-column">
                    <img class="fer w-50  mt-3 mb-3 ml-auto mr-auto" src="img/vector/stl_player_blue.svg" alt="Club supporter">
                    <h3 class="text-center subtitle mb-5">Masa social</h3>
                    <ul class="text-normal">
                        <li>
                            <span class="text-bold">Disfrutan de una App de su club</span>
                            <ul>
                                <li>Resultados y clasificaciones</li>
                                <li>Comunicación directa del club</li>
                                <li>Notificaciones</li>
                            </ul>
                        </li>
                        <li>
                            <span class="text-bold">Realizan los trámites online</span>
                            <ul>
                                <li>Inscripciones</li>
                                <li>Pagos de cuotas</li>
                                <li>Compra de material</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <div class="section">
            <contact-anchor />
        </div>
    </div>
</template>

<script>
    import ContactAnchor from "./components/ContactAnchor";

    export default {
        name: 'Administration',
        components: {ContactAnchor},
        bodyClass: 'service-page',
    };
</script>

<style scoped>
    .flex-column{
        flex: 1 1 0;
    }
    .text-normal {
        font-size: 1.2rem;
    }
</style>
