<template>
    <div class="post-page">
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <h1 class="title text-info text-left text-underline mb-4">Planes de precios</h1>
                <h2 class="text-black-50 text-left">Contrata el plan que más se adapta a tu Club</h2>
            </div>
        </div>
        <div class="section">
            <pricing-section />
            <contact-anchor />
        </div>
    </div>
</template>

<script>
import ContactAnchor from "./components/ContactAnchor";
import PricingSection from "./components/PricingSection";

export default {
    name: 'Pricing',
    components: {
        PricingSection,
        ContactAnchor
    },
};
</script>
