<template>
  <tooltip
    class="item m-4"
    popper-class="tooltip-logo-club"
    :content="clubName"
  >
    <div
      class="rounded-circle"
      :style="{ backgroundImage: `url('${clubLogo}')` }"
    >
      <a class="stretched-link" target="_blank" :href="clubUrl" v-if="clubUrl">
      </a>
    </div>
  </tooltip>
</template>
<script>
import { Tooltip } from "element-ui";

export default {
  name: "club-logo",
  props: {
    clubName: {
      type: String,
      required: true,
    },
    clubLogo: {
      type: String,
      required: true,
    },
    clubUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    Tooltip,
  },
};
</script>
<style>
.tooltip-logo-club {
  z-index: 10 !important ;
}
</style>
<style scoped>
div {
  height: 7.5em;
  width: 7.5em;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
}
</style>