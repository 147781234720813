<template>
    <section class="page-column d-flex flex-column flex-wrap p-5 justify-content-around align-items-center mt-4">
        <div class="text-center mb-5">
            <p>
                Cluber es Agente Digitalizador autorizado por el Gobierno de España. Consulta <a href="https://www.cluber.es/kit-digital">aquí</a> cómo podemos ayudarte a conseguir hasta 12.000€ para digitalizar tu club.
            </p>
            <a href="https://cluber.es/kit-digital">
                <img class="mx-auto d-block col-10 col-lg-10 col-xl-11" src="img/digitalkit/CONBANDERA_KITDIGITAL.png" alt="digitalizadores" />
            </a>
        </div>
        <div class="mb-5 mt-0 text-center text-medium">
            <img height="120px" class="mb-4" style="transform: translateX(25%)" src="/img/logos/daxia_v3.png" alt="Icono Certificado Daxia">
            <p>
                Cluber Siguetuliga SL. ha obtenido de <a target="_blank" href="https://daxia.es/">Daxia</a> el certificado anual por haber llevado a cabo todas las acciones requeridas en protección de datos para cumplir con la normativa vigente. Puedes consultar aquí el <a target="_blank" href="https://stl-clubs.s3-eu-west-1.amazonaws.com/docs/certificado-daxia-biashara.pdf">Certicado anual de Cumplimiento Normativo</a>
            </p>
        </div>
        <div class="d-flex flex-wrap justify-content-around align-items-center">
          <img v-for="(logo, index) in entityNames" class="support-logo" :src="`/img/logos/${logo.name}.${logo.format}`" :key="index">
        </div>
        <p class="text-center small mt-4 mb-0">
            Cluber Siguetuliga SL. no marco do programa de préstamos IFI INNOVA, conta co apoio do Igape, de GAIN, da Xunta de Galicia e da Unión Europea.
        </p>
        <p class="d-block text-center medium mt-4 mb-0">
          <a
              href="http://www.igape.es/images/PublicidadComunitaria/FEDER-IG190-Re-acciona-2020.png"
              target="_blank"
              rel="noreferrer">
            Esta empresa fue beneficiaria de una ayuda Re-acciona.
          </a>
        </p>
    </section>
</template>

<script>
    export default {
        name: "SupportEntitiesSection",
        data () {
            return {
                entityNames: [
                    { name: 'xunta', format: 'svg' },
                    { name: 'gain', format: 'svg' },
                    { name: 'igape', format: 'svg' },
                    { name: 'ue', format: 'png' },
                    { name: 'galicia', format: 'svg' }
                ]
            }
        }
    }
</script>
