<template>
    <div class="section-services" style="padding-top: 200px" id="services">
        <div class="container">
            <FeatureBlock
                :title="access.title"
                :subtitle="access.subtitle"
                :feature-list="access.featureList"
                :button="access.button"
                img-src="/img/mocks/app_sample_access-control.png"
            />

            <FeatureBlock
                :title="payments.title"
                :subtitle="payments.subtitle"
                :feature-list="payments.featureList"
                img-src="/img/mocks/web_sample_cluber.png"
            />

            <FeatureBlock
                :title="cargos.title"
                :subtitle="cargos.subtitle"
                :feature-list="cargos.featureList"
                img-src="/img/mocks/app_sample_charges.png"
            />

            <FeatureBlock
                :title="comms.title"
                :subtitle="comms.subtitle"
                :feature-list="comms.featureList"
                img-src="/img/mocks/app_sample_messages2.png"
            />

            <FeatureBlock
                :title="app.title"
                :subtitle="app.subtitle"
                :feature-list="app.featureList"
                img-src="/img/mocks/app_sample_cluber.png"
            />
        </div>
    </div>
</template>

<script>
    import FeatureBlock from "../../components/FeatureBlock";
    export default {
        name: "ServicesSection",
        components: {FeatureBlock},
        data () {
            return {
                access: {
                    title: 'Control de accesos y aforo',
                    subtitle: 'App para control de accesos, límite de aforo y gestión de asientos',
                    featureList: [
                        '<strong class="highlight">Escanea el código QR del carnet</strong> del socio y de las <strong class="highlight">entradas/reservas</strong> del evento o registra manualmente',
                        'Control en tiempo real de los <strong class="highlight">accesos y aforo</strong> de la instalación, desde uno o más teléfonos simultáneamente',
                        '<strong class="highlight">Todo el proceso es digital</strong>, facilitando el registro previo, el acceso a la instalación y la trazabilidad posterior'
                    ],
                    button: { route: 'control-de-accesos', label: 'MÁS INFORMACIÓN' }
                },
                management: {
                    title: 'Gestión fácil y automática',
                    subtitle: 'Los pagos al club de patrocinadores, socios o padres se reflejarán automáticamente en tu cuenta. Realiza un seguimiento de todos los cargos de manera sencilla y totalmente segura.'
                },
                payments: {
                    title: 'Automatiza el cobro de las cuotas del club',
                    subtitle: '¡Todos los pagos al día sin esfuerzo!',
                    featureList: [
                        'Los socios y jugadores podrán <strong class="highlight">darse de alta y renovar desde el móvil</strong>, sin ningún esfuerzo',
                        '<strong class="highlight">Programa los cargos</strong> según la cuota elegida o personalízalos... una vez <strong class="highlight">¡para toda la temporada!</strong>',
                        '<strong class="highlight">Se acabaron los recibos devueltos,</strong> los impagos y las colas en la oficina'
                    ]
                },
                cargos: {
                    title: 'Realiza cualquier cargo a través de Cluber',
                    subtitle: 'La opción más cómoda para socios, padres... y para el club',
                    featureList: [
                        '<strong class="highlight">Simplifica todos los cobros</strong> a socios/padres/aficionados: entradas, material deportivo, viajes a torneos...',
                        'Envías un mensaje al móvil de socios y padres, que <strong class="highlight">aceptan el pago o renovación simplemente pulsando un botón.</strong>',
                        '¿Y si no pertenece al club? Deja los datos que le solicites y realiza el <strong class="highlight">pago con tarjeta como en cualquier comercio online.</strong>'
                    ]
                },
                comms: {
                    title: 'Mejora la comunicación de tu club',
                    subtitle: 'Crea un canal oficial de comunicación donde centralices tus mensajes',
                    featureList: [
                        'Envía <strong class="highlight">mensajes individuales o difusiones</strong> a grupos o equipos de tu club',
                        'Las comunicaciones serán <strong class="highlight">solo entre usuario y club.</strong> Evita los grupos de WhatsApp.',
                        '¡Ya nadie se perderá nada a través de este <strong class="highlight">canal oficial de comunicación del Club!</strong>'
                    ]
                },
                app: {
                    title: '¡TU CLUB TIENE SU PROPIA APP!',
                    subtitle: 'Socios y padres, más informados y conectados que nunca',
                    featureList: [
                        'Comunicaciones oficiales',
                        'Notificaciones',
                        'Organización de eventos',
                        'Renovaciones',
                        'Horarios y Resultados',
                        'Pagos seguros',
                        'Y mucho más!'
                    ]
                }
            }
        }
    }
</script>
