<template>
    <div>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <div class="photo-container">
                    <img src="img/imago.png" alt="" />
                </div>
                <h1 class="title text-black">Servicio de Padres</h1>
                <h2 class="text-black-50">¡Los padres ya no tienen que volver por la oficina del club!</h2>
            </div>
        </div>
        <div class="section">
            <div class="container pt-5">
                <div class="button-container">
                    <a href="https://app.cluber.es/register" class="btn btn-secondary btn-round btn-xl">
                        Probar
                    </a>
                </div>

                <div class="row m-auto col-md-6 col-xl-12">
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Los padres podrán hacer la inscripción de sus hijos desde el móvil.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Se acabaron los recibos devueltos, los impagos y las colas en la oficina.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>El club programa los cargos a los padres una vez y se olvida… ¡para toda la temporada!</strong>
                    </h4>
                </div>


                <div class="d-flex flex-row justify-content-center m-5">
                    <iframe
                            class="page-header-image mt-5"
                            src="https://player.vimeo.com/video/365586261"
                            width="640" height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen>
                    </iframe>
                </div>

                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">¿Cómo funciona?</h2>
                </div>
                <div class="description text-left d-flex flex-wrap mb-5">
                    <ol class="col-md-12">
                        <li>Publicas el enlace de inscripción o se lo haces llegar a los padres.</li>
                        <li>Desde su móvil, los padres rellenan todos los datos que pida el club y eligen el método de
                            pago (de entre los que permita el club): tarjeta, domiciliación u otros medios.</li>
                        <li>El club programa los cargos para toda la temporada (Ejemplo: La cuota “Infantil” es de 10
                            cargos de 25 euros) y Cluber se encarga de enviarlos a su debido tiempo</li>
                        <li>¡Ya está! Los padres han inscrito a su hijo desde el móvil y el club se asegura cobrar,
                            tener todo organizado y no hacer más trabajo hasta la próxima temporada.</li>
                    </ol>
                </div>
                <!-- End Section -->

                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">Desde ahora podrás...</h2>
                </div>
                <div class="description text-left d-flex flex-wrap">
                    <ul class="col-md-12 col-xl-6">
                        <li>Gestionar diferentes cuotas (diferentes modalidades, precios...)</li>
                        <li>Automatizar pagos recurrentes con tarjeta y/o domiciliación bancaria</li>
                        <li>Enviar y recibir comunicaciones a los padres</li>
                        <li>Dar acceso a una sección privada a los padres para consultar novedades, horarios de
                            partidos, resultados o mostrar su historial de pagos</li>
                    </ul>
                    <ul class="col-md-12 col-xl-6">
                        <li>Personalizar el formulario de alta para pedir los datos que necesites</li>
                        <li>Llevar la gestión de pagos en efectivo o en cuenta</li>
                        <li>Invitar a la venta de productos del club o a participar en actividades</li>
                        <li>Posibilidad de realizar “preinscripciones”, para captar jugadores interesados</li>
                    </ul>
                </div>
                <!-- End Section -->


                <faq-section :faqs="faqs"></faq-section>

                <contact-anchor/>

            </div>
        </div>
    </div>
</template>

<script>
import StoryblokClient from 'storyblok-js-client'
import FaqSection from "./components/FaqSection";
import ContactAnchor from "./components/ContactAnchor";

const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
})

export default {
    name: 'Parents',
    components: {ContactAnchor, FaqSection},
    bodyClass: 'profile-page',

    data () {
        return {
            loading: true,
            faqs: null,
        }
    },

    mounted () {
        this.fetchData()
            .then(({faqs}) => {
                this.faqs = faqs
                this.loading = false
            })
    },

    methods: {
        async fetchData () {
            this.loading = true
            const parentsResponse = await api.get('cdn/stories/padres')
            return { faqs: parentsResponse.data.story.content['Faqs'] }
        }
    }
};
</script>

<style></style>
