import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Sponsors from './pages/Sponsors.vue';
import Members from './pages/Members.vue';
import Parents from './pages/Parents.vue';
import Blog from './pages/Blog.vue';
import BlogPost from './pages/BlogPost.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import Pricing from "./pages/Pricing";
import Administration from "./pages/Administration";
import Form from "./pages/Form";
import AccessControl from "./pages/AccessControl";
import FormSuccess from "./pages/FormSuccess";
import DefaultFormSuccess from "./pages/DefaultFormSuccess";
import PSD2Info from './pages/PSD2Info';
import SuccessStories from './pages/SuccessStories';
import LandingVideo from './pages/LandingVideo'
import Faq from './pages/Faq';
import SearchClubs from "./pages/SearchClubs";
import DigitalKit from "./pages/DigitalKit";
import SurfLicensesPortal from './pages/surf-licenses-portal/SurfLicensesPortal.vue';
import SurfLicensesFederation from './pages/surf-licenses-portal/SurfLicensesFederation.vue';
import SurfLicensesClub from './pages/surf-licenses-portal/SurfLicensesClub.vue'
Vue.use(Router);

// If this property is set, a sponsorship banner will appear above the main nav
const showSponsorshipBanner = {
  sponsorshipBanner: true,
}

// If this meta property is set, logo will not be clickable and some items on the page will be hidden,
// to avoid user visits leaking to another pages in the site
const closedLanding = {
  avoidVisitLeaks: true,
}

// If this meta property is set, logo will not be clickable and some items on the page will be hidden,
// to avoid user visits leaking to another pages in the site. With no dependency on other params.
const forceClosedLanding = {
  forceAvoidVisitLeaks: true,
}

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/surf-licenses/:federationId',
      name: 'surf-portal',
      components: { default: SurfLicensesPortal},
    },
    {
      path: '/surf-licenses/:federationId/clubs',
      name: 'surf-portal-clubs',
      components: { default: SurfLicensesClub},
    },
    {
      path: '/surf-licenses',
      name: 'surf-portal-federations',
      components: { default: SurfLicensesFederation},
    },
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
      },
    },
    {
      path: '/psd2',
      name: 'psd2',
      components: { default: PSD2Info, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/casos-de-exito',
      name: 'casos-de-exito',
      components: { default: SuccessStories, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
      },
      meta: {
        ...closedLanding,
      },
      children: [
        {
          path: ':kindofsport',
          meta: {
            ...closedLanding,
          }
        }
      ],
    },
    {
      path: '/patrocinadores',
      name: 'patrocinadores',
      components: { default: Sponsors, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/socios',
      name: 'socios',
      components: { default: Members, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/padres',
      name: 'padres',
      components: { default: Parents, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/blog',
      name: 'blog',
      components: { default: Blog, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/blog/categoria/',
      name: 'noCategory',
      redirect: {
        name: 'blog'
      }
    },
    {
      path: '/blog/categoria/:slug',
      name: 'categories',
      components: { default: Blog, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/blog/:slug',
      name: 'blogPost',
      components: { default: BlogPost, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/precios',
      name: 'pricing',
      components: { default: Pricing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/administracion',
      name: 'administration',
      components: { default: Administration, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/control-de-accesos',
      name: 'control-de-accesos',
      components: { default: AccessControl, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/form',
      name: 'form',
      components: { default: Form, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/form-success',
      name: 'FormSuccess',
      components: { default: FormSuccess, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/default-form-success',
      name: 'DefaultFormSuccess',
      components: { default: DefaultFormSuccess, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/faq',
      name: 'Faq',
      components: { default: Faq, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/buscador',
      name: 'Buscador',
      components: { default: SearchClubs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'blue' }
      }
    },
    {
      path: '/kit-digital',
      name: 'kit-digital',
      components: { default: DigitalKit, header: MainNavbar, footer: MainFooter },
    },
    {
      path: '/patrocinio',
      name: 'patrocinio',
      components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
        default: { formUrl: 'https://webforms.pipedrive.com/f/1AgMcamdPhxy9Koq5yvP2V0Xv0T7qsreWhOVIZCwIgKb3QRZE0H5OaK0FBuemanbt' },
      },
      meta: {
        ...closedLanding,
        ...forceClosedLanding,
      },
    },
      {
        path: '/colaboraciones',
        name: 'colaboraciones',
        components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
        props: {
          header: { colorOnScroll: 100 },
          footer: { backgroundColor: 'blue' },
          default: { formUrl: 'https://webforms.pipedrive.com/f/1v53G06uiqr5wsAIwYKapQQ5FFZd5VLPRdQ1r8rTHzNNAiUnfVuClbbroXuKG3jP5' },
        },
        meta: {
          ...closedLanding,
          ...forceClosedLanding,
        },      
    },
    {
      path: '/landing',
      name: 'landing',
      components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
        default: { formUrl: 'https://webforms.pipedrive.com/f/1v53G06uiqr5wsAIwYKapQQ5FFZd5VLPRdQ1r8rTHzNNAiUnfVuClbbroXuKG3jP5' },
      },
      meta: {
        ...closedLanding,
        ...forceClosedLanding,
      },      
    },
    {
      path: '/SolicitudKitDigital',
      name: 'SolicitudKitDigital',
      components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
        default: { formUrl: 'https://webforms.pipedrive.com/f/1yMBG2gZkr1VTNTJij6YAF9qMQLdNaqly0BFiKGgHo0dOwhSRLnriXjgs8X8oV6AH' },
      },
      meta: {
        ...closedLanding,
        ...forceClosedLanding,
      },      
    },
    {
      path: '/abanca',
      name: 'abanca',
      components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
        default: { formUrl: 'https://webforms.pipedrive.com/f/1r4gR13QFlXlfjwM9iASWqzpRkRvaAXNswekD5Zzz66Sd6sol5MibaU4tszljhvBV' },
      },
      meta: {
        ...closedLanding,
        ...forceClosedLanding,
      },      
    },    
    {
      path: '/atletismo',
      name: 'atletismo',
      components: { default: LandingVideo, header:MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: 'blue' },
        default: { formUrl: 'https://webforms.pipedrive.com/f/2VMMxOIjgbSHUshvnR37o0za9uOQiLWXQ6v4kgAfShB3cFBXYnUj6nnmcf5xI2eZB' },
      },
      meta: {
        ...closedLanding,
        ...forceClosedLanding,
      },      
    },        
    {
      path: '*',
      name: 'not-found',
      redirect: {
        name: 'index'
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
