var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container pt-5"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('faq-section',{attrs:{"faqs":_vm.faqs}}),_c('contact-anchor')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header clear-filter",attrs:{"filter-color":"greenTogreen"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"photo-container"},[_c('img',{attrs:{"src":"img/imago.png","alt":""}})]),_c('h1',{staticClass:"title text-black"},[_vm._v("Servicio de Socios")]),_c('h2',{staticClass:"text-black-50"},[_vm._v("¡Conseguir y renovar socios ya no es un problema!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-container mb-5"},[_c('a',{staticClass:"btn btn-secondary btn-round btn-xl",attrs:{"href":"https://app.cluber.es/register"}},[_vm._v(" Probar ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-auto col-md-6 col-xl-12"},[_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("No tendrás que perseguir nunca más a los socios para renovarlos.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("No necesitas que vayan por el campo para contactar con ellos.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Enviando un whatsapp haces un socio... ¡para toda la vida!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-center m-5"},[_c('iframe',{staticClass:"page-header-image mt-5",attrs:{"src":"https://player.vimeo.com/video/365585619","width":"640","height":"360","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("¿Cómo funciona?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap mb-5"},[_c('ol',{staticClass:"col-md-12"},[_c('li',[_vm._v("Tienes un listado con todos los socios, si cobras en mano, lo marcas como pagado.")]),_c('li',[_vm._v("A los que no han pagado o no has podido ver les puedes enviar un whatsapp desde la propia herramienta. Desde ahí, el socio puede pagar al momento con tarjeta.")]),_c('li',[_vm._v("Ya tienes todo el dinero sin necesidad de perseguirlos... Ahora, a captar nuevos. Comparte un enlace en RRSS o envíalo por Whatsapp a jugadores, familiares... NUEVOS SOCIOS A LA VISTA!")]),_c('li',[_vm._v("Y el próximo año... todos renovados con un click!!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("Desde ahora podrás...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap"},[_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Gestionar diferentes cuotas (diferentes modalidades, precios...)")]),_c('li',[_vm._v("Automatizar pagos recurrentes con tarjeta y/o domiciliación bancaria")]),_c('li',[_vm._v("Enviar y recibir comunicaciones de tus socios")]),_c('li',[_vm._v("Dar acceso a una sección privada a tus socios para consultar novedades, horarios de partidos, resultados o mostrar su carné virtual")])]),_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Personalizar el formulario de alta")]),_c('li',[_vm._v("Socios relacionados (socio familiar, socio protector...)")]),_c('li',[_vm._v("Llevar la gestión de pagos en efectivo o en cuenta")]),_c('li',[_vm._v("Invitar a la venta de productos del club o a participar en actividades")]),_c('li',[_vm._v("Posibilidad de renovar a tus socios automáticamente, previa notificación")])])])
}]

export { render, staticRenderFns }