<template>
    <div class="section" id="blog">
        <div class="container d-flex flex-column">
            <h2 v-if="!showAll" class="title"><span class="text-underline">Blog</span></h2>
            <div class="row justify-content-lg-start">
                <div
                    v-for="post in posts"
                    :key="`${post.id}${ulid()}`"
                    class="col-md-6 col-xl-4"
                >
                    <router-link
                        :to="{name: 'blogPost', params: {slug: post.slug}}"
                        class="no-underline"
                    >
                        <card :class="cardClass">
                            <template slot="image">
                                <img :src="getPostThumbnail(post)" alt="Imagen cabecera del artículo" />
                            </template>
                            <template slot="header">
                                <h3 class="card-title title-up">{{ post.content.title }}</h3>
                            </template>
                            <template slot="raw-content" v-if="!compact">
                                <span v-html="post.content.summary" class="card-body text-ellipsis"/>
                                <router-link
                                    class="btn btn-primary btn-round"
                                    :to="{name: 'blogPost', params: {slug: post.slug}}"
                                >
                                    Leer artículo
                                </router-link>
                            </template>
                        </card>
                    </router-link>
                </div>
            </div>

            <a
                v-if="!showAll"
                class="btn btn-primary btn-round btn-simple align-self-center"
                href="/blog"
            >
                Ver más artículos
            </a>
        </div>
    </div>
</template>

<script>
import Card from "../../components/Cards/Card";
import { ulid } from 'ulid';

export default {
    name: "featuredPostsSection",
    components: {
        Card
    },
    data() {
        return {
            ulid
        }
    },
    props: {
        posts: { type: Array, default: () => [] },
        showAll: { type: Boolean, default: false },
        compact: { type: Boolean, default: false }
    },
    computed: {
        cardClass () {
            return this.compact ? 'card-post card--round-l card-post--compact' : 'card-post card--round-l'
        }
    },
    methods: {
        getPostThumbnail(post) {
            if (post.content.main_image) {
                return this.$transformImage(post.content.main_image, '0x200')
            } else {
                return '/img/default-article-thumbnail.jpg'
            }
        }
    }
}
</script>
