/**
 * You can register global mixins here
 */

const GlobalMixins = {
  install(Vue) {
    Vue.mixin({
      mounted() {
        let { bodyClass } = this.$options;
        if (bodyClass) {
          document.body.classList.add(bodyClass);
        }
      },
      beforeDestroy() {
        let { bodyClass } = this.$options;
        if (bodyClass) {
          document.body.classList.remove(bodyClass);
        }
      },
      computed: {
        $targetMonth () {
          const currentDate = new Date();
          currentDate.setMonth(currentDate.getMonth() + 1)
          return currentDate.toLocaleString('es-es', { month: "long" });
        }
      },
      methods: {
        $transformImage(image, option) {
          const imageService = '//img2.storyblok.com/';
          const path = image.replace('//a.storyblok.com', '');
          return imageService + option + path
        }
      }
    });
  }
};

export default GlobalMixins;
