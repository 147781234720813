<template>
  <ul>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--plus mr-2"></i>
      <span>Pack ESTÁNDAR</span>
    </li>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
      <span>Gestión personalizada</span>
    </li>
    <li class="d-flex flex-column">
      <span class="mb-3">
        Podemos ayudarte a conseguir hasta 12.000€ para digitalizar tu club con Kit Digital. Infórmate
        <a href="https://cluber.es/kit-digital">aquí.</a>
      </span>
      <a href="https://cluber.es/kit-digital">
        <img class="mx-auto d-block" src="img/digitalkit/digitalkit-logo.png" alt="digitalizadores" />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PricingFeaturesStarter"
}
</script>
