<template>
    <div>
        <div class="page-header clear-filter page-header--access" filter-color="greenTogreen">
            <div class="container">
                <div class="photo-container">
                    <img src="img/cluber_imago_2.1.jpg" alt="Logo de Cluber" v-on:click="showModal" />
                </div>
                <h1 class="title text-black">{{ access.title }}</h1>
                <h2 class="text-black-50">{{ access.subtitle }}</h2>
            </div>
        </div>
        <div class="section">
            <div class="container d-flex justify-content-center">
                <a class="btn btn-highlight text-large" href="/form">PROBAR AHORA</a>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="d-flex flex-row justify-content-center mb-5">
                    <iframe
                            class="page-header-image mt-5"
                            src="https://player.vimeo.com/video/495796946"
                            width="768" height="420"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen>
                    </iframe>
                </div>

                <div class="row m-auto col-md-6 col-xl-12 mt-5">
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Controla en tiempo real el aforo y acceso a instalaciones deportivas, eventos o entrenamientos</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Solo se necesita una persona y un móvil para escanear el QR de las entradas compradas online o en taquilla</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Cluber registra todos los datos y en caso de una incidencia rastrea quién compartió instalaciones</strong>
                    </h4>
                </div>

            </div>
        </div>

        <div class="d-flex flex-column justify-content-center">
            <div class="d-flex flex-row justify-content-center m-5">
                <div class="container mt-5">
                    <FeatureBlock
                        :feature-list="access.featureList"
                        img-src="/img/mocks/app_sample_access-control.png"
                    />
                </div>
            </div>

            <AccessControlNews />

            <div class="section bg-primary">
                <div class="container d-flex justify-content-center">
                    <a class="btn btn-highlight-inverse text-large" href="/form">PROBAR AHORA</a>
                </div>
            </div>
<!--            <div class="d-flex flex-column justify-content-center bg-primary pt-5" id="access-form">-->
<!--                <div class="container">-->
<!--&lt;!&ndash;                    <div class="d-flex flex-row">&ndash;&gt;-->
<!--&lt;!&ndash;                        <h2 class="title text-underline text-underline&#45;&#45;white">Solicita una prueba GRATIS</h2>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <AccessControlForm background="primary"/>-->
<!--                </div>-->
<!--            </div>-->

            <section class="d-flex flex-row justify-content-center p-5 bg-primary">
                <img v-on:click="showModal" class="mock-image mt-5" src="/img/mocks/app_sample_cluber.png" alt="Ejemplos de la app en móviles" data-toggle="modal" data-target="#exampleModal">
            </section>
            <modal :show.sync="isModalVisible" headerClasses="justify-content-center">
                <h4 slot="header" class="title title-up w-100">DESCARGA CLUBER EN TU SMARTPHONE</h4>
                <div class="d-flex flex-column justify-content-around align-items-center">
                    <a href='https://play.google.com/store/apps/details?id=com.siguetuliga.clubes' target="_blank" width="300px">
                        <img alt='Disponible en Google Play' src='https://play.google.com/intl/es-419/badges/static/images/badges/es_badge_web_generic.png' width="300px"/></a>
                    <a
                        href="https://apps.apple.com/es/app/cluber/id1522454919"
                        target="_blank" width="300px"
                    >
                        <img alt='Disponible en Google Play' src='/img/vector/badge_app-store.svg' width="300px" style="padding: 18px"/>
                    </a>
                </div>
                <template slot="footer">
                    <button type="danger" v-on:click="hideModal">Cerrar</button>
                </template>
            </modal>

<!--            <AccessControlNews />-->
        </div>
    </div>
</template>

<script>
import FeatureBlock from "../components/FeatureBlock";
import Modal from "../components/Modal";
import AccessControlNews from "./components/AccessControlNews";

export default {
    name: 'AccessControl',
    components: {
        AccessControlNews,
        Modal,
        FeatureBlock,
    },
    bodyClass: 'profile-page',

    data () {
        return {
            loading: true,
            faqs: null,
            access: {
                title: 'Control de accesos y aforo',
                subtitle: 'App para control de accesos y límite de aforo con gestión de asientos',
                featureList: [
                    '<strong class="highlight">Escanea el código QR del carnet</strong> del socio y de las <strong class="highlight">entradas/reservas</strong> del evento o registra manualmente',
                    'Control en tiempo real de los <strong class="highlight">accesos y aforo</strong> de la instalación, desde uno o más teléfonos simultáneamente',
                    '<strong class="highlight">Todo el proceso es digital</strong>, facilitando el registro previo, el acceso a la instalación y la trazabilidad posterior'
                ]
            },
            modals: {
                classic: false
            }
        }
    },
    computed: {
      isModalVisible () {
          return this.modals.classic
      }
    },
    methods: {
        showModal () {
            this.modals.classic = true
        },
        hideModal () {
            this.modals.classic = false
        }
    }
};
</script>

<style scoped lang="scss">
    @import '../assets/scss/now-ui-kit/variables';
    @import '../assets/scss/now-ui-kit/mixins';
    .bg-white {
        background: white;
    }
    .photo-container img{
        height: 100% !important;
        object-fit: cover !important;
        overflow: hidden;
        width: 100% !important;
    }
    .page-header--access {
        min-height: 450px !important;
    }
    .mock-image {
        cursor: pointer;
        height: auto;
        width: auto;
        z-index: 2;
        @include breakpoint('medium') {
            height: 411px;
            width: auto;
        }
    }
</style>
