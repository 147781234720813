<template>
    <div class="section" id="faq">
        <div class="container">
            <div class="row mt-4">
                <div
                    v-for="faq in faqs"
                    :key="faq._uid"
                    class="col-md-12 col-xl-6 mb-4"
                >
                    <h4 class="subtitle text-left">{{ faq.title }}</h4>
                    <div class="description text-left" v-html="faq.description.html"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import StoryblokClient from "storyblok-js-client";

  const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
      clear: 'auto',
      type: 'memory'
    }
  })
    export default {
        name: "FaqSection",
      data() {
          return {
            loading: true,
            faqs: null
          }
      },
        computed: {
          homeHeader () {
            return this.homePage.content.body.filter(item => item.component === 'Header')[0]
          },
          showFeaturedPosts() {
            return this.blogPosts && this.blogPosts.length
          },
          showFaqs() {
            return this.faqs && this.faqs.length
          }
        },
        mounted () {
          this.fetchData()
              .then(({faqs}) => {
                this.faqs = faqs
                this.loading = false
              })
        },
        methods: {
          async fetchData () {
            this.loading = true
            const homeResponse = await api.get('cdn/stories/home')
            return {
              faqs: homeResponse.data.story.content['Faqs']
            }
          }
        }
    }
</script>
