<template>
    <div>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <h1 class="title text-info text-left text-underline">Cluber Blog</h1>
            </div>
        </div>
        <div class="section pt-0">
            <BlogCategoryMenu v-if="categoryNamesList.length" :category-names-list="categoryNamesList"/>
            <div class="container d-flex flex-column">
                <SpinnerLoader v-if="loading"/>
                <template v-else>
                    <div v-if="$route.params.slug" class="d-flex text-large mb-4">
                        <router-link :to="{name: 'blog', params: { reload: true }}">Blog</router-link>
                        <span>{{ `/${$route.params.slug}` }}</span>
                    </div>
                    <featured-posts-section :posts="blogPosts" :show-all="true"/>
                    <button
                        v-if="!reachedLastPost"
                        class="btn btn-primary btn-round btn-simple align-self-center"
                        @click="loadMoreArticles"
                    >
                        <template v-if="!loadingMore">Ver más artículos</template>
                        <SpinnerLoader v-else/>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client'
import FeaturedPostsSection from "./components/featuredPostsSection";
import SpinnerLoader from "../components/SpinnerLoader";
import BlogCategoryMenu from "./components/BlogCategoryMenu";

const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
})

export default {
    name: 'BlogPost',

    bodyClass: 'post-page',

    components: {
        BlogCategoryMenu,
        SpinnerLoader,
        FeaturedPostsSection
    },

    props: {
        reload: { type: Boolean, default: false }
    },

    data () {
        return {
            loading: true,
            loadingMore: false,
            blogPosts: [],
            currentPage: 1,
            articlesPerPage: 30,
            reachedLastPost: false,
            categoryNamesList: []
        }
    },
    mounted () {
        this.loadCategories()
        this.loadArticles()
    },
    updated () {
        if (this.$route.params.reload) {
            this.blogPosts = [];
            this.currentPage = 1;
            this.$route.params.reload = false;
            this.loadArticles()
        }
    },

    methods: {
        async fetchCategories () {
            let options = {
                starts_with: 'blog/'
            };
            const response = await api.get('cdn/tags', options);
            return { categories: response.data.tags.filter(tag => tag.name !== 'featured') }
        },
        async fetchData () {
            let options = {
                starts_with: 'blog/',
                per_page: this.articlesPerPage,
                page: this.currentPage,
                sort_by: 'first_published_at:desc',
                filter_query: {
                  "hide_from_list": {
                    "is": false
                  }
                }
            };
            if (this.$route.name === 'categories' && !!this.$route.params.slug) {
                options = {...options, with_tag: this.$route.params.slug}
            }
            const blogResponse = await api.get('cdn/stories', options);
            return { blog: blogResponse.data.stories }
        },
        fetchAndSetData () {
            this.fetchData()
                .then(({blog}) => {
                    if (!blog.length && this.currentPage === 1) {
                        this.$router.push({name: 'blog', params: { reload: true }})
                    }
                    this.blogPosts = [...this.blogPosts, ...blog];
                    this.loading = false;
                    this.loadingMore = false;
                    this.currentPage += 1;
                    this.reachedLastPost = blog.length < this.articlesPerPage
                })
        },
        loadCategories () {
            this.fetchCategories()
                .then(({ categories }) => {
                    this.categoryNamesList = categories.map(item => item.name)
                })
        },
        loadArticles () {
            this.loading = true;
            this.fetchAndSetData()
        },
        loadMoreArticles () {
            this.loadingMore = true;
            this.fetchAndSetData()
        }
    }
};
</script>
