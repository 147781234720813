var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container pt-5"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('faq-section',{attrs:{"faqs":_vm.faqs}}),_c('contact-anchor')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header clear-filter",attrs:{"filter-color":"greenTogreen"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"photo-container"},[_c('img',{attrs:{"src":"img/imago.png","alt":""}})]),_c('h1',{staticClass:"title text-black"},[_vm._v("Servicio de Padres")]),_c('h2',{staticClass:"text-black-50"},[_vm._v("¡Los padres ya no tienen que volver por la oficina del club!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-container"},[_c('a',{staticClass:"btn btn-secondary btn-round btn-xl",attrs:{"href":"https://app.cluber.es/register"}},[_vm._v(" Probar ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-auto col-md-6 col-xl-12"},[_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Los padres podrán hacer la inscripción de sus hijos desde el móvil.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("Se acabaron los recibos devueltos, los impagos y las colas en la oficina.")])]),_c('h4',{staticClass:"col-md-12 col-xl-4 blockquote"},[_c('strong',[_vm._v("El club programa los cargos a los padres una vez y se olvida… ¡para toda la temporada!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-center m-5"},[_c('iframe',{staticClass:"page-header-image mt-5",attrs:{"src":"https://player.vimeo.com/video/365586261","width":"640","height":"360","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("¿Cómo funciona?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap mb-5"},[_c('ol',{staticClass:"col-md-12"},[_c('li',[_vm._v("Publicas el enlace de inscripción o se lo haces llegar a los padres.")]),_c('li',[_vm._v("Desde su móvil, los padres rellenan todos los datos que pida el club y eligen el método de pago (de entre los que permita el club): tarjeta, domiciliación u otros medios.")]),_c('li',[_vm._v("El club programa los cargos para toda la temporada (Ejemplo: La cuota “Infantil” es de 10 cargos de 25 euros) y Cluber se encarga de enviarlos a su debido tiempo")]),_c('li',[_vm._v("¡Ya está! Los padres han inscrito a su hijo desde el móvil y el club se asegura cobrar, tener todo organizado y no hacer más trabajo hasta la próxima temporada.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row justify-content-lg-start"},[_c('h2',{staticClass:"title text-underline"},[_vm._v("Desde ahora podrás...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description text-left d-flex flex-wrap"},[_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Gestionar diferentes cuotas (diferentes modalidades, precios...)")]),_c('li',[_vm._v("Automatizar pagos recurrentes con tarjeta y/o domiciliación bancaria")]),_c('li',[_vm._v("Enviar y recibir comunicaciones a los padres")]),_c('li',[_vm._v("Dar acceso a una sección privada a los padres para consultar novedades, horarios de partidos, resultados o mostrar su historial de pagos")])]),_c('ul',{staticClass:"col-md-12 col-xl-6"},[_c('li',[_vm._v("Personalizar el formulario de alta para pedir los datos que necesites")]),_c('li',[_vm._v("Llevar la gestión de pagos en efectivo o en cuenta")]),_c('li',[_vm._v("Invitar a la venta de productos del club o a participar en actividades")]),_c('li',[_vm._v("Posibilidad de realizar “preinscripciones”, para captar jugadores interesados")])])])
}]

export { render, staticRenderFns }