<template>
  <div class="default-page">
    <!-- Cabecera -->
    <div
      class="page-header clear-filter page-header--psd2"
      filter-color="greenTogreen"
    >
      <div class="container">
        <div class="photo-container">
          <img src="img/logos/3ds.png" alt="" />
        </div>
        <h1 class="title text-black">Información sobre 3DS/3D Secure</h1>
      </div>
    </div>
    <!-- Texto de Información. -->
    <div class="container-fluid centered-layout">
      <div class="row justify-content-center">
        <div class="mx-2 mx-lg-0 p-5 px-lg-0 w-100 col-lg-8">
          <p>
            <strong>3D Secure o 3DS</strong> es un protocolo de seguridad que
            tiene como objetivo prevenir el fraude y fortalecer la protección de
            tus pagos online en compras con tarjeta de crédito o débito, tal
            como lo establece la nueva <strong>Directiva Europea PSD2.</strong>
          </p>
          <h4>¿Cómo te afecta la PSD2 en tus pagos online?</h4>
          <p>
            Con el fin de mejorar la seguridad en tus compras online, a partir
            de ahora es posible que tu banco te solicite una
            <strong>doble verificación</strong>
            en el proceso de pago:
          </p>

          <p>
            <strong>1. </strong>Como es habitual, introduces los datos de tu
            tarjeta.
          </p>

          <p>
            <strong>2. </strong>Una vez pulses <strong>Pagar</strong>, tu Banco
            podría solicitarte una validación adicional para finalizar el pago.
            Es posible que esta validación sea introduciendo un código temporal
            de un solo uso (que recibirás de tu Banco vía SMS) o que tengas que
            realizar la validación desde tu teléfono directamente en la APP de
            tu Banco.
          </p>

          <p>
            <strong>3. </strong>
            Una vez autorizada la operación serás redirigido a nuestra web o
            verás un botón que indica
            <strong>Continuar para volver al Sitio WEB</strong>. En este momento
            te confirmaremos que tu compra ha sido realizada con éxito.
          </p>

          <p>
            Para poder realizar tus pagos online
            <strong
              >asegúrate de que tus tarjetas tienen activado el nuevo protocolo
              3DS</strong
            >. Si tienes alguna duda, ponte en contacto con tu banco y sigue sus
            indicaciones para la validación de pagos en compras online.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "psd2-info",
  bodyClass: "default-page",
};
</script>
<style scoped>
.page-header--psd2 {
  height: auto !important;
}

p {
  font-size: 1.4em;
  font-weight: 400;
  text-align: justify;
}
h4 {
  font-weight: bold;
}
</style>