<template>
  <div>
      <div class="page-header clear-filter" filter-color="greenTogreen">
          <div class="container d-flex flex-column mt-4 col-10">
            <CluberForm prefix="CTRL"/>
          </div>
      </div>
    <support-entities-section />
  </div>
</template>
<script>
import CluberForm from "@/pages/components/CluberForm";
export default {
    name: 'Form',
    bodyClass: 'index-page',
    components: {
      CluberForm,
        SupportEntitiesSection: () => import("./components/SupportEntitiesSection")
    }
};
</script>
