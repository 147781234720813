var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingPage)?_c('div',{style:({
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      height: '100vh'
    })},[_c('SpinnerLoader')],1):_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"page-header clear-filter page-header--success flex order-0",attrs:{"filter-color":"greenTogreen"}},[_c('div',{staticClass:"container mt-4 d-flex justify-content-lg-between flex-column flex-lg-row align-items-lg-start align-items-center"},[(_vm.loadingCounters)?_c('SpinnerLoader'):_c('div',{staticClass:"text-center mt-4 mt-lg-0 metric-size"},[_c('img',{staticStyle:{"height":"7.5em"},attrs:{"src":"/img/vector/icons/icon-user.svg"}}),_c('h2',{staticClass:"title pt-2 mb-0",staticStyle:{"font-size":"4.5em"}},[_vm._v(_vm._s(_vm.counters.users))]),_c('h2',{staticClass:"title pt-1"},[_vm._v("personas")])]),_c('div',{staticClass:"text-center mt-4 mt-lg-0 metric-size"},[_c('img',{staticStyle:{"height":"7.5em"},attrs:{"src":"/img/vector/icons/shield.svg"}}),_c('h2',{staticClass:"title pt-2 mb-0",staticStyle:{"font-size":"4.5em"}},[_vm._v(_vm._s(_vm.counters.teams))]),_c('h2',{staticClass:"title pt-1"},[_vm._v("equipos")])]),_c('div',{staticClass:"text-center mt-4 mt-lg-0 metric-size"},[_c('img',{staticStyle:{"height":"7.5em"},attrs:{"src":"/img/vector/icons/runningman.png"}}),_c('h2',{staticClass:"title pt-2 mb-0",staticStyle:{"font-size":"4.5em"}},[_vm._v(_vm._s(_vm.counters.sportsCount))]),_c('h2',{staticClass:"title pt-1"},[_vm._v("disciplinas deportivas")])])],1)]),_c('div',{class:[
        'container d-flex flex-column align-items-center',
        'order-2'
      ]},[(_vm.loadingClubs)?_c('SpinnerLoader'):(_vm.clubs.length === 0)?_c('h3',[_vm._v(" No hay clubes disponibles en estos momentos. ")]):_c('div',[_c('h2',{staticClass:"title text-center"},[_vm._v("Nos avalan")]),_c('div',{staticClass:"d-flex flex-row justify-content-center flex-wrap"},_vm._l((_vm.federationLogos),function(federation){return _c('ClubLogo',{key:federation.id,attrs:{"clubLogo":federation.logo,"clubUrl":federation.url,"clubName":federation.name}})}),1),_c('div',{staticClass:"d-flex flex-row justify-content-center flex-wrap"},_vm._l((_vm.clubLogos),function(club){return _c('ClubLogo',{key:club.id,attrs:{"clubLogo":club.logo,"clubUrl":club.url,"clubName":club.name}})}),1)])],1),_c('div',{staticClass:"container d-flex flex-column align-items-center mb-5 mt-5 order-3"},[_c('h2',{staticClass:"title"},[_vm._v("Cómo ayudamos a nuestros clientes")]),_c('div',{staticClass:"col-xl-10 col-sm-11 col-12"},[_c('div',{staticClass:"mx-3",attrs:{"id":"chart-grid"}},[_vm._l((_vm.chartData),function(item,index){return [_c('div',{key:item.name,staticClass:"text-right"},[_c('h5',{staticClass:"text-bold m-2 chart-text"},[(index <= 1)?_c('span',{staticClass:"chartbar-highlight-icon"},[_vm._v(" ✪ ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{key:item.name + '-progress',staticClass:"px-0 py-1"},[_c('div',{staticClass:"h-100 d-flex align-items-center",style:({
                  width: item.rate,
                })},[_c('div',{class:[
                    { 'chartbar-highlight': index <= 1 },
                    'chartBar px-2 d-flex align-items-center'
                  ]})])])]})],2)])]),(!_vm.extraRouteParam)?_c('div',{staticClass:"mb-5 container d-flex flex-column align-items-center order-4"},[_c('h2',{staticClass:"title"},[_vm._v("Conoce algunas de nuestras historias de éxito")]),(_vm.loadingBlogEntries)?_c('SpinnerLoader'):(_vm.blogEntries.length === 0)?_c('h3',[_vm._v(" No hay publicaciones disponibles en estos momentos. ")]):_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},_vm._l((_vm.blogEntries),function(post){return _c('div',{key:post.id,staticClass:"col-md-6 col-xl-4"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{ name: 'blogPost', params: { slug: post.slug } }}},[_c('Card',{attrs:{"type":"post"}},[_c('template',{slot:"image"},[_c('img',{attrs:{"src":_vm.getPostThumbnail(post),"alt":"Imagen cabecera del artículo"}})]),_c('template',{slot:"header"},[_c('h3',{staticClass:"card-title title-up"},[_vm._v(_vm._s(post.content.title))])]),_c('template',{slot:"raw-content"},[_c('span',{staticClass:"card-body text-ellipsis",domProps:{"innerHTML":_vm._s(post.content.summary)}}),_c('router-link',{staticClass:"btn btn-primary btn-round",attrs:{"to":{ name: 'blogPost', params: { slug: post.slug } }}},[_vm._v(" Leer artículo ")])],1)],2)],1)],1)}),0),_c('router-link',{attrs:{"to":{ name: 'categories', params: { slug: 'casos de éxito' } }}},[_c('span',{staticClass:"btn btn-round btn-simple btn-primary btn-sm"},[_vm._v(" Ver más historias ")])])],1):_vm._e(),_c('div',{class:[
        'py-5 container-fluid d-flex flex-column align-items-center',
        _vm.isEnclosedLandingPage ? 'order-1' : 'order-4',
      ],style:({
        'background-image': `url(${_vm.extraParamsImage})`,
        'background-size': 'cover',
        'background-position': 'center',
      }),attrs:{"id":"club-contact-form"}},[_c('div',{staticClass:"col-12 col-md-6"},[(!_vm.extraRouteParam)?_c('CluberForm',{attrs:{"prefix":"SUC"}}):_c('div',{staticClass:"pipedriveWebForms bg-white rounded p-5 d-flex justify-content-center",attrs:{"data-pd-webforms":"https://forms.zohopublic.eu/cluber/form/SolicituddeinformacinKitDigital/formperma/4T4oPtwcC1qBp28i61jFa2EzYcWILz6MGeEAisZbzlg"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }