<template>
    <div class="post-page">
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <h1 class="title text-info text-left text-underline mb-4">Preguntas más frecuentes</h1>
                <h2 class="text-black-50 text-left">Encuentra solución a las dudas más habituales</h2>
            </div>
        </div>
        <div class="section">
            <FaqSection />
            <contact-anchor />
        </div>
    </div>
</template>

<script>
import ContactAnchor from "./components/ContactAnchor";
import FaqSection from "@/pages/components/FaqSection";

export default {
    name: 'Faq',
    components: {FaqSection, ContactAnchor},
};
</script>
