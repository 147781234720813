<template>
    <section class="feature-block section" style="background-color: transparent">
        <div class="content flex-column">
            <h2 class="feature-block__title">{{ title }}</h2>
            <h3 class="feature-block__subtitle">{{ subtitle }}</h3>
            <div class="feature-block__row">
                <ul
                    class="feature-block__list pt-5"
                >
                    <li
                        v-for="(item, index) in featureList"
                        :key="index"
                        v-html="item"
                    />

                  <router-link
                      v-if="button"
                      class="btn btn-highlight d-block p-4 text-large"
                      :to="{ name: button.route }"
                  >
                    {{ button.label }}
                  </router-link>
                </ul>
                <img v-if="imgSrc" class="feature-block__image" :src="imgSrc" alt="Imagen de pagos">
            </div>
        </div>
        <!--Waves Container-->
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 24 150 28" preserveAspectRatio="xMinYMin slice" shape-rendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
                <use xlink:href="#gentle-wave" x="148" y="9" class="waves-07" />
                <use xlink:href="#gentle-wave" x="8" y="3" class="waves-05" />
                <use xlink:href="#gentle-wave" x="348" y="5" class="waves-03" />
                <use xlink:href="#gentle-wave" x="48" y="14" class="waves-1"/>
            </g>
        </svg>
        <!--Waves end-->
    </section>
</template>

<script>
export default {
    name: "FeatureBlock",
    props: {
      title: { type: String, default: null },
      subtitle: { type: String, default: null },
      featureList: { type: Array, default: null },
      imgSrc: { type: String, default: null },
      button: { type:Object, default: null }
    }
}
</script>

<style lang="scss">
    @import '../assets/scss/now-ui-kit/variables';
    @import '../assets/scss/now-ui-kit/mixins';


    .content {
        position:relative;
        height:auto;
        margin-top: 0px;
        z-index: 1;
    }
    .waves {
        position: absolute;
        object-fit: contain;
        overflow: visible;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom:-37px; /*Fix for safari gap*/
        &-1 {
            fill: rgb(0, 128, 255);
        }
        &-03 {
            fill: rgba(0, 128, 255, .3);
        }
        &-05 {
            fill: rgba(255, 255, 255, .5);
        }
        &-07 {
            fill: rgba(0, 128, 255, .7);
        }
    }

    .feature-block {
        color: $secondary-color;
        margin-bottom: 3rem;
        min-height:380px;
        padding: 2vw 3vw 0;
        overflow: visible;
        &__title {
            color: white;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: .5em;
        }
        &:first-of-type {
            .feature-block__title {
                color: $primary-color;
            }
        }
        &__subtitle {
            /*background-color: white;*/
            color: $secondary-color-alert;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
        }
        &__row {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint('large') {
                flex-wrap: nowrap;
            }
        }
        &__list {
            display: inline-block;
            font-size: 1.3rem;
            flex-basis: 100%;
            flex-shrink: 0;
            padding-left: 0;
            @include breakpoint('large') {
                flex-basis: 49%;
            }
            li {
                background-color: $secondary-color;
                color: #b2dbf8;
                font-weight: 500;
                margin-bottom: .4em;
                padding: .5em 1em;
                list-style: none;
            }
        }
        &__image {
            align-self: flex-end;
            display: inline-flex;
            height: auto;
            flex-basis: 350px;
            flex-shrink: 0;
            object-fit: contain;
            @include breakpoint('large') {
                flex-basis: 49%;
                width: 49%;
            }
        }
        @include breakpoint('large') {
            margin-bottom: 9rem;
            .feature-block__title {
                color: $primary-color;
            }
            &:nth-child(even) {
                .feature-block__subtitle,
                .feature-block__title {
                    color: white;
                    text-align: right;
                }
                .feature-block__list {
                    align-self: flex-end;
                    order: 2;
                }
                .feature-block__image {
                    order: 1;
                }
                .waves-05 {
                    fill: rgba(0, 128, 255, .5);
                }
                .waves-1 {
                    fill: rgb(255, 255, 255);
                }
            }
            .feature-block__list {
                padding-left: inherit;
            }
        }
    }
    .highlight {
        color: white;
        font-weight: 700;
    }
</style>
