<template>
    <div class="section" id="precios">
      <!--
        <div class="align-items-center d-flex bg-secondary rounded-lg justify-content-center p-3 px-lg-3 p-lg-0 mb-5 mx-auto col-11 col-md-12 col-lg-11 flex-wrap flex-lg-nowrap">
          <span class="rounded-lg bg-danger p-2 mb-3 mb-md-0 text-bold">¡NUEVO!</span>
          <p class="ml-0 ml-md-4 text-bold text-center w-100 w-md-auto m-md-0">
            Actualmente estamos patrocinando clubes deportivos ¿Te interesa?
          </p>
          <a
            href="http://sponsorship.cluber.es"
            class="btn btn-primary btn-round btn-lg ml-0 ml-md-4 col-12 col-md-2"
          >
            Solicitar
          </a>
          <a
            href="https://cluber.es/blog/patrocinio-clubes"
            class="btn btn-tertiary btn-round btn-sm ml-0 ml-md-4 col-12 col-md-2"
          >
            Más info
          </a>
        </div>
        -->
        <div class="container d-lg-none">
            <spinner-loader v-if="loadingPlans" />
            <div class="row" v-else>
                <div class="col-sm-12 col-md-6 ml-auto col-lg-3 col-xl-3 mr-auto">
                  <PricingSectionTest v-bind:plan="plans[0]">
                    <template v-slot:hint>
                      <p class="price-value__offer mt-5 mb-3">
                        <span class="text-medium">{{plans[0].content.description}}</span>
                      </p>
                    </template>
                    <template v-slot:bottom>
                      <PricingFeaturesStarter />
                      <a href="https://app.cluber.es/register#test" class="btn btn-secondary btn-round btn-lg mt-auto">Pruébalo ahora</a>
                      <p class="text-small text-gray d-flex align-items-center flex-wrap">
                        <pricing-tax-popover price="*Precios con IVA"/>
                      </p>
                    </template>
                  </PricingSectionTest>
                </div>
                <div class="col-sm-12 col-md-6 ml-auto col-lg-3 col-xl-3 mr-auto">
                  <PricingSectionBasic v-bind:plan="plans[1]">
                    <template v-slot:hint>
                      <p class="price-value__offer">
                        <span class="text-medium">{{ plans[1].content.description }}</span>
                      </p>
                    </template>
                    <template v-slot:bottom>
                      <PricingFeaturesAdvanced />
                      <a href="https://app.cluber.es/register#basic" class="btn btn-secondary btn-round btn-lg mt-auto">Pruébalo ahora</a>
                      <p class="text-small text-gray d-flex align-items-center flex-wrap">
                        <pricing-tax-popover price="*Precios con IVA"/>
                      </p>
                    </template>
                  </PricingSectionBasic>
                </div>
                <div class="col-sm-12 col-md-6 ml-auto col-lg-3 col-xl-3 mr-auto">
                  <PricingSectionStandard v-bind:plan="plans[2]">
                    <template v-slot:hint>
                      <p class="price-value__offer">
                        <span class="text-medium">{{plans[2].content.description}}</span>
                      </p>
                    </template>
                    <template v-slot:bottom>
                      <PricingFeaturesAdvanced />
                      <a href="https://app.cluber.es/register#standard" class="btn btn-secondary btn-round btn-lg mt-auto">Pruébalo ahora</a>
                      <p class="text-small text-gray d-flex align-items-center flex-wrap">
                        <pricing-tax-popover price="*Precios con IVA"/>
                      </p>
                    </template>
                  </PricingSectionStandard>
                </div>
                <div class="col-sm-12 col-md-6 ml-auto col-lg-3 col-xl-3 mr-auto">
                  <PricingSectionPremium v-bind:plan="plans[3]">
                    <template v-slot:hint>
                      <p class="price-value__offer mt-4">
                        <span class="text-medium">{{plans[3].content.description}}</span>
                      </p>
                    </template>
                    <template v-slot:bottom>
                      <PricingFeaturesPremium />
                      <a href="https://app.cluber.es/register#premium" class="btn btn-secondary btn-round btn-lg mt-auto">Pruébalo ahora</a>
                      <p class="text-small text-gray d-flex align-items-center flex-wrap">
                        <pricing-tax-popover price="*Precios con IVA"/>
                      </p>
                    </template>
                  </PricingSectionPremium>
                </div>
            </div>
        </div>
<!--      Desktop View-->
        <div class="container card d-none d-lg-flex pricing-grouped pb-1 pt-5">
          <spinner-loader v-if="loadingPlans" />
          <div class="d-flex" style="border-bottom: 1px solid rgba(0,0,0,.1)" v-else>
            <PricingSectionTest style="flex-basis: 33%;border-right: 1px solid rgba(0,0,0,.1)" v-bind:plan="plans[0]">
              <template v-slot:hint>
                <p class="price-value__offer mt-5 mb-3">
                  <span class="text-medium">{{ plans[0].content.description }}</span>
                </p>
              </template>
              <template v-slot:bottom>
                <PricingFeaturesStarter />
              </template>
            </PricingSectionTest>
            <div class="d-flex d-flex flex-column" style="flex-basis: 66%;">
              <div class="d-flex">
                <div class="flex-column" >
                  <div class="d-flex">
                    <PricingSectionBasic style="border-right: 1px solid rgba(0,0,0,.1)" v-bind:plan="plans[1]">
                      <template v-slot:hint>
                      </template>
                      <template v-slot:bottom>
                      </template>
                    </PricingSectionBasic>
                    <PricingSectionStandard v-bind:plan="plans[2]">
                      <template v-slot:hint>
                      </template>
                      <template v-slot:bottom>
                      </template>
                    </PricingSectionStandard>
                  </div>
                  <p class="price-value__offer mt-4">
                    <span class="text-medium">{{ plans[1].content.description }}</span>
                  </p>
                </div>
              </div>
              <hr>
              <PricingFeaturesAdvanced :columns="true"/>
            </div>
            <PricingSectionPremium style="border-left: 1px solid rgba(0,0,0,.1); flex-basis: 33%" v-bind:plan="plans[3]">
                <template v-slot:hint>
                  <p class="price-value__offer mt-1">
                    <span>{{ plans[3].content.description }}</span>
                  </p>
                </template>
                <template v-slot:bottom>
                  <PricingFeaturesPremium />
                </template>
              </PricingSectionPremium>
          </div>
          <a href="https://app.cluber.es/register#desktop" class="btn btn-secondary btn-round btn-lg ml-auto mr-auto mt-4 mb-5"
             style="width: 200px">
            Pruébalo ahora
          </a>
          <p class="text-small text-gray d-flex align-items-center flex-wrap">
            <pricing-tax-popover price="*Precios con IVA"/>
          </p>
        </div>
    </div>
</template>
<script>
import StoryblokClient from "storyblok-js-client";
import SpinnerLoader from "../../components/SpinnerLoader.vue";
import { Popover } from 'element-ui';
import PricingSectionTest from "./PricingSectionTest";
import PricingSectionBasic from "./PricingSectionBasic";
import PricingSectionStandard from "./PricingSectionStandard";
import PricingSectionPremium from "./PricingSectionPremium";
import PricingFeaturesPremium from "@/pages/components/PricingFeaturesPremium";
import PricingFeaturesAdvanced from "@/pages/components/PricingFeaturesAdvanced";
import PricingFeaturesStarter from "@/pages/components/PricingFeaturesStarter";
import PricingTaxPopover from "@/pages/components/PricingTaxPopover";

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export default {
    name: 'PricingSection',
    components: {
      SpinnerLoader,
      PricingTaxPopover,
      PricingFeaturesStarter,
      PricingFeaturesPremium,
      PricingFeaturesAdvanced,
      PricingSectionPremium,
      PricingSectionStandard,
      PricingSectionBasic,
      PricingSectionTest,
      [Popover.name]: Popover
    },
    data () {
      return {
        loadingPlans: true,
        plans: [],
        feeText: 'La tarifa de servicio se calculará en función del volumen gestionado por la entidad, siendo nunca superior al 5% del importe transaccionado y se aplicará por defecto al usuario que realice cada pago.'
      };
    },
    mounted () {
      this.fetchSetPlans();
    },
    methods: {
      async fetchPlans(){
        const options = {
          starts_with: "pricing-plans/",
          sort_by: "content.order:asc",
        };
        const response = await api.get("cdn/stories", options);
        return response.data.stories;
      },
      fetchSetPlans(){
        this.fetchPlans().then((data) => {
          this.plans = data;
          this.loadingPlans = false;
        })
      }
    }
};
</script>
<style lang="scss" scoped>
    .tab-content.tab-content-padding {
        padding: 20px;
    }
    .pricing-grouped {
      * {
        border-radius: 0;
        box-shadow: 0 0 0;
      }
    }
    hr {
      margin-top: 1rem;
      margin-bottom: 0;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
</style>
