<template>
    <form
        class=" shadow px-lg-4 p-2 py-5 rounded d-flex flex-column align-items-center m-auto"
        name="cluber-contact-form"
        @submit="formSubmit"
        method="POST"
        action="/default-form-success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        id="cluber-contact-form"
        style="background-color: white;margin:0 auto"
    >
      <input type="hidden" name="form-name" value="cluber-contact-form" />
      <div>
        <h2 class="text-center title pt-0">
          ¿Cómo podemos ayudaros?
        </h2>
        <input name="bot-field" class="honeypot-field" />
        <div id="checkbox-grid" class="w-100 px-lg-1 px-lg-0 px-4">
          <div
              class="mb-4"
              v-for="(item, index) in funcionalidades"
              :key="index"
          >
            <input
                class="mr-2 d-inline"
                type="checkbox"
                :name="item.id"
                :id="item.id"
                value="Seleccionado"
                :checked="prefix==='CTRL' && item.id == 'access-control'"
            />
            <label
              class="d-inline title"
              :for="item.id"
            >
            {{ item.name }}
            </label>
          </div>
        </div>
      </div>
      <div id="input-group" class="col-xl-9 col-md-12 col-12 mt-2">
        <fg-input
            v-model="name"
            :required="true"
            class="no-border"
            placeholder="Nombre"
            name="Nombre"
            type="text"
            addon-left-icon="stl-icon stl-icon--user"
        >
        </fg-input>

        <fg-input
            v-model="mail"
            :required="true"
            class="no-border"
            placeholder="Email"
            name="Mail"
            type="email"
            addon-left-icon="stl-icon stl-icon--mail"
        >
        </fg-input>

        <fg-input
            v-model="phone"
            :required="true"
            class="no-border"
            placeholder="Teléfono"
            name="Teléfono"
            type="tel"
            addon-left-icon="stl-icon stl-icon--phone"
        >
        </fg-input>

        <fg-input
            v-model="club"
            :required="true"
            class="no-border"
            placeholder="Club al que perteneces"
            name="Club"
            type="text"
            addon-left-icon="stl-icon stl-icon--team"
        >
        </fg-input>

        <fg-input
            v-model="message"
            class="no-border"
            addon-left-icon="stl-icon stl-icon--message"
        >
            <textarea
                placeholder="Escribe tu mensaje"
                :required="true"
                type="text"
                class="form-control"
                name="Mensaje"
                aria-describedby="addon-right addon-left"
            />
        </fg-input>
        <TermsAndConditions />
        <n-button
          class="mx-auto d-block"
          native-type="submit"
          type="secondary"
          round
          size="lg"
          v-if="!isLoading"
        >
          <span class="px-4" >
            Enviar  
          </span>
        </n-button>
        <SpinnerLoader 
          class="mx-auto d-block"
          style="height: 54px;"
          v-else
        ></SpinnerLoader>
      </div>
      <div
        v-if="error"
        class="form-error"
      >
        Ha habido algún problema al intentar enviar el formulario. Por favor, espera unos momentos o escríbenos directamente a
        <a href="mailto:info@cluber.es">info@cluber.es</a>
      </div>
    </form>
</template>

<script>
import { FormGroupInput, Button } from '@/components';
import TermsAndConditions from '@/pages/components/TermsAndConditions';
import pipedriveMixin from '@/mixins/pipedriveMixin';
import SpinnerLoader from '../../components/SpinnerLoader.vue';

export default {
  name: 'CluberForm',
  components: {
    TermsAndConditions,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    SpinnerLoader
  },
  mixins: [pipedriveMixin],
  props: ['prefix'],
  data() {
    return {
      funcionalidades: [
        { id: 'sponsorship', name: 'Patrocinio' },
        { id: 'unpaids', name: 'Reducción de impagos'},
        { id: 'fee-management', name: 'Gestión Automática de Cuotas' },
        { id: 'player-signup', name: 'Inscripción deportistas' },
        { id: 'partner-recruitment', name: 'Captación de socios' },
        { id: 'access-control', name: 'Control de accesos' },
        { id: 'inscriptions', name: 'Inscripciones campus, torneos...' },
        { id: 'online-shop', name: 'Tienda Online' },
        { id: 'others', name: 'Otros' },
      ],
      //DATOS FORM
      name: '',
      mail: '',
      phone: '',
      club: '',
      message: '',
      termsChecked: false,
      isLoading: false,
      error: false,
    }
  },
  methods: {
    formSubmit(event) {
      event.preventDefault();
      this.isLoading = true;
      this.error = false;
      const formData = new FormData(
        document.getElementById('cluber-contact-form')
      );

      if (formData.get('bot-field').length == 0) {
        const payload = {
          name: formData.get('Nombre'),
          email: formData.get('Mail'),
          phone: formData.get('Teléfono'),
          postal_address: '',
        };


        this.createPersonAndLead(payload, formData)
          .then(() => {
            event.target.submit();
          }).catch(() => {
            this.error = true;
            this.isLoading = false;
          })
      } else {
        event.target.submit();
      }
    }
  }
}
</script>

<style scoped lang="scss">
  #checkbox-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 0.5em;
    text-align: left;
    label {
      color: black;
    }
  }

  @media (max-width: 992px) {
    #checkbox-grid {
      grid-template-columns: 1fr;
    }
  }

  label {
    font-size: 1em !important;
  }
  #input-group {
    font-size: 1.2em;
  }


  .honeypot-field {
    display: none;
  }

  .form-error {
    background-color: rgb(255, 210, 210);
    border: 2px solid red;
    border-radius: 4px;
    padding: 1rem;
  }

</style>
