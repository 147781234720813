import EPipedriveLabels from "../model/EPipedriveLabels"
export default {
    methods: {
        async assignLabels() {
          return new Promise((resolve, reject) => {
            const allLabels = EPipedriveLabels

            let checked_boxes = [];
            let labels_array = [];
            this.funcionalidades.forEach((func) => {
              if (document.getElementById(func.id).checked) {
                checked_boxes.push(func.name.toLowerCase());
              }
            });

            allLabels.forEach((label) => {
              if (checked_boxes.includes(label.name.toLowerCase())) {
                labels_array.push(`"${label.name}"`);
              }
            });

            resolve(labels_array)
          })
        },
        async getUserPrint () {
            return await fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    const userAgent = window.navigator.userAgent;
                    const timeStamp = new Date().getTime();
                    return`${ip};${userAgent};${timeStamp}`;
                });
        },
        async createPersonAndLead(payload, formData) {
          const prefix = this.prefix || 'PROD';
          const title = this.prefix ? `${prefix}_${formData.get("Club")}` : formData.get("Club")
          payload = {
            ...payload,
            title,
            "a5fed850df6afa91fe60b4c973a6d3c496b6308b": formData.get("terms-and-conditions"),
            "9d6537d890ec9f4b95a0330d59d378fb0f192387": await this.getUserPrint(),
            "9f49f3d1cd81fce080ef74b6b90a38cd0b4447c6": formData.get("Mensaje"),
            label_ids: await this.assignLabels(),
          }

          // Llamada a través de Integromat
          const data = await fetch(
            `https://hook.eu1.make.com/4y8bwhxv86cfrtlwezqyvp8ft3dbkem7`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(payload),
            }
          ).then((response) => response.json())
          .catch(error => {
            throw new Error(error)
          });
          return data;
        },
    }
}
