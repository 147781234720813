<script>
import StoryblokClient from "storyblok-js-client";
import SpinnerLoader from '../../components/SpinnerLoader.vue'

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default {
  data(){
    return {
      loading: true,
      clubList: [],
      federation: {},
    }
  },
  components: {
    SpinnerLoader
  },
  mounted () {
    this.aggregateData().then(()=>{
      this.loading = false;
    })
  },
  methods: {
    async aggregateData(){
      this.federation = await this.fetchFederation();
      this.clubList = await this.fetchClubs();
    },
    async fetchFederation() {
      const federations = await api.get('cdn/stories', {
        starts_with: 'surfcollection',
        content_type: 'federationCollection',
        filter_query: {
          'id': { in: this.$route.params.federationId}
        },
        per_page: 1
      })

      return federations.data.stories[0];
    },
    async fetchClubs() {
      const clubs = await api.get('cdn/stories', {
        starts_with: 'surfcollection',
        content_type: 'clubsCollection',
        filter_query: {
          'federation_id': { in: this.$route.params.federationId}
        }
      })

      return clubs.data.stories;
    }
  },  
}


</script>
<template>
  <div>
  <div class="container d-flex flex-column align-items-center my-5">
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="container d-flex flex-column align-items-center" v-else>
      <div class="h4 mt-2 w-100">
        <router-link :to="{ name: 'surf-portal-federations'}">Inicio</router-link> / 
        <router-link :to="{ name: 'surf-portal'}">{{ federation.content.name }}</router-link> / 
        Clubes
      </div>
      <h1 class="font-weight-bold">Licencias Surf Temporada 2024</h1>
      <h4 class="font-weight-bold mt-3">Selecciona el club al que pertenezcas</h4>
      <div class="row w-100 justify-content-center" v-if="clubList.length > 0">
        <div
          class="col-md-3 my-1 d-flex flex-column align-items-center px-3"
          :key="club.id"
          v-for="club in clubList"
        >

          <a 
            :href="club.content.license_url"
            class="no-underline"
          >
            <div class="p-3 border rounded border-dark m-4">
              <img :src="club.content.logo.filename"/>
            </div>
            <span class="text-center text-dark d-block font-weight-bold">{{ club.content.name }}</span>
          </a>
        </div>
      </div>
      <div v-else>
        <h4 class="mt-4">No se han encontrado clubes asociados a la federación seleccionada</h4>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-column align-items-center my-4">
      <p class="font-weight-bold">¿Alguna duda?</p>
      <ul class="pl-0">
        <li>Correo electrónico: <a href="mailto:administracion@fesurf.es">administracion@fesurf.es</a></li>
        <li>Teléfono: <a href="tel:+34981311666">981 311 666</a></li>
      </ul>

    </div>
  </div>
</template>
<style scoped>

.club-logo {
  max-height: 6rem;
}

a:hover  {
  text-decoration: none !important;
}
a:active  {
  text-decoration: none !important;
}

</style>