<template>
    <section class="container">
        <div class="d-flex flex-column justify-content-center align-items-center mt-lg-5 bg-light p-2">
            <h2 class="title">¿Hablamos?</h2>
            <h5 class="description">Déjanos tu mensaje, nosotros nos encargamos.</h5>
            <a href="/#contacto" class="btn btn-secondary btn-simple btn-round btn-lg m-4">Contactar</a>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ContactAnchor"
    }
</script>
