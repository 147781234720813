<script>
export default {
  data: () => ({
    blocks: [
      {
        image: 'img/homepage/player.svg',
        title: 'Masa social del club',
        perks: [
          'Multiperfil',
          'Multiclub',
          'Pagos automáticos',
          'Carnet digital con QR',
          'Notificaciones'
        ],
      },
      {
        image: 'img/homepage/shield-club.svg',
        title: 'Club',
        perks: [
          'Inscripciones',
          'Mensajeria',
          'Control de accesos',
          'Cobros automáticos de cuotas, campus, ropa...',
        ],
      },
      {
        image: 'img/homepage/player-club.svg',
        title: 'Para todos',
        perks: [
          'Formación personalizada',
          'Soporte por chat + email',
          'Cumplimiento RGPD',
          'Acceso simple con móvil',
          'Disponible en web y app'
        ],
      }
    ],
  })
}
</script>

<template>
  <div class="m-auto col-12 col-md-8 flex-wrap d-flex justify-content-center justify-content-lg-between">
    <div
      class="shadow bg-white border border-light d-inline-block py-3 px-5 position-relative"
      :style="{ 'margin-top': '7rem', 'width': '345px' }"
      v-for="block in blocks"
      :key="block.title"
    >
      <div class="avatar-circle bg-black rounded-circle">
        <img class="mx-auto d-block col-12 mt-3" :src="block.image" alt="non payment" />
      </div>
      <p class="text-bold text-center mb-2 mt-3 h3">
        {{ block.title }}
      </p>
      <ul class="list-unstyled mb-0">
        <li
          v-for="(perk, index) in block.perks"
          :key="perk"
          :class="[
            'py-3 text-center',
            index === block.perks.length -1 ? '' : 'border-bottom'
          ]"
        >
          {{ perk }}
        </li>
      </ul>
    </div>
  </div>

</template>

<style>
.avatar-circle {
  height: 100px;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  position: absolute;
  top: -90px;
  background: white;
  border: .4rem solid #0080ff;
}

.block-pp {
  width: 500px;
}
</style>
