import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import StoryblokVue from 'storyblok-vue'
import LoadScript from 'vue-plugin-load-script';
import vueDebounce from 'vue-debounce'
import Vue2TouchEvents from 'vue2-touch-events'
import 'core-js'

// Font Awesome-related initialization
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faWhatsapp, faTelegram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Add the specific imported icons
library.add(faEnvelope);
library.add(faFacebook);
library.add(faTwitter);
library.add(faWhatsapp);
library.add(faTelegram);
library.add(faLinkedin);

// Enable the FontAwesomeIcon component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);

const SocialSharing = require('vue-social-sharing');

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(StoryblokVue);
Vue.use(SocialSharing);
Vue.use(LoadScript);
Vue.use(vueDebounce);
Vue.use(Vue2TouchEvents)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
