<template>
  <div>
    <div class="page-header clear-filter">
      <div
          class="page-header-image"
      >
<!--        <picture>-->
<!--          <source srcset="img/landing_cluber.webp" type="image/webp">-->
<!--          <source srcset="/img/landing_cluber.jpg" type="image/jpeg">-->
<!--          <img src="/img/landing_cluber.jpg" alt="Cluber imagen de cabecera">-->
<!--        </picture>-->

          <picture>
              <img src="/img/vector/cluber_drawing.svg" alt="Representación virtual de uso de Cluber" class="animated-image">
          </picture>

          <!--Waves Container-->
          <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 24 150 28" preserveAspectRatio="xMinYMid meet" shape-rendering="auto">
              <defs>
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="parallax">
                  <use xlink:href="#gentle-wave" x="148" y="9" class="waves-07" />
                  <use xlink:href="#gentle-wave" x="8" y="3" class="waves-05" />
                  <use xlink:href="#gentle-wave" x="348" y="5" class="waves-03" />
                  <use xlink:href="#gentle-wave" x="48" y="7" class="waves-1"/>
              </g>
          </svg>
          <!--Waves end-->
      </div>
      <header-section v-if="homePage" :home-header="homeHeader" />
    </div>

    <div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <picture>
                  <source srcset="img/devices.webp" type="image/webp">
                  <source srcset="/img/devices.png" type="image/png">
                  <img src="/img/devices.png" alt="Múltiples dispositivos visualizando Cluber">
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <social-mass />

    <services-section />

    <featured-posts-section :posts="blogPosts"/>

    <contact-form ></contact-form>

    <support-entities-section />
  </div>
</template>
<script>
import { Button } from '@/components';
import StoryblokClient from 'storyblok-js-client'
import HeaderSection from "./components/HeaderSection";
import ServicesSection from "./components/ServicesSection";
import FeaturedPostsSection from "./components/featuredPostsSection";
import ContactForm from './components/ContactForm';
import SupportEntitiesSection from "./components/SupportEntitiesSection";
import SocialMass from './SocialMass.vue'

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default {
  name: 'index',

  bodyClass: 'index-page',

  components: {
    SupportEntitiesSection,
    HeaderSection,
    ServicesSection,
    FeaturedPostsSection,
    ContactForm,
    [Button.name]:Button,
    SocialMass,
  },

  data () {
    return {
      loading: true,
      homePage: null,
      blogPosts: null,
    }
  },

  computed: {
    homeHeader () {
      return this.homePage.content.body.filter(item => item.component === 'Header')[0]
    },
    showFeaturedPosts() {
      return this.blogPosts && this.blogPosts.length
    }
  },

  mounted () {
    this.fetchData()
      .then(({home, blog}) => {
        this.homePage = home
        this.blogPosts = blog
        this.loading = false
      })
  },

  methods: {
    async fetchData () {
      this.loading = true
      const homeResponse = await api.get('cdn/stories/home')
      const blogResponse = await api.get('cdn/stories', { starts_with: 'blog/', with_tag: 'featured' })
      return {
        home: homeResponse.data.story,
        blog: blogResponse.data.stories
      }
    }
  }
};
</script>
<style lang="scss" scoped>

    .waves {
        position: absolute;
        object-fit: contain;
        overflow: visible;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom:-37px; /*Fix for safari gap*/
        &-1 {
            fill: rgb(0, 128, 255);
        }
        &-03 {
            fill: rgba(0, 128, 255, .3);
        }
        &-05 {
            fill: rgba(255, 255, 255, .5);
        }
        &-07 {
            fill: rgba(0, 128, 255, .7);
        }
    }
</style>
