<template>
    <img src="../../public/img/vector/loader.svg" alt="loading spinner">
</template>

<script>
export default {
    name: "SpinnerLoader"
}
</script>

<style scoped>

</style>
