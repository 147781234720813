import iconCluster1 from '../../public/img/vector/icons/icon-location-cluster.svg'
import iconActive1 from '../../public/img/vector/icons/icon-location-active.svg'
import iconInactive1 from '../../public/img/vector/icons/icon-location-inactive.svg'
export default {
    data () {
        return {
            markers: [],
            markersInfo: [],
            apiLoaded: false,
            map: null,
            service: null
        }
    },
    props: ['iconCluster', 'iconActive', 'iconInactive'],
    computed: {
        locationClusterIcon () {
            return this.iconCluster || iconCluster1
        },
        locationActiveIcon () {
            return this.iconActive || iconActive1
        },
        locationInactiveIcon () {
            return this.iconInactive || iconInactive1
        },
    },
    methods: {
        importScript () {
            return new Promise((resolve, reject) => {
                let googleMapsScript = document.createElement('script')
                googleMapsScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`)
                document.head.appendChild(googleMapsScript);
                let markerClusterer = document.createElement('script');
                markerClusterer.setAttribute('src', `https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/src/markerclusterer.js`);
                document.head.appendChild(markerClusterer);
                this.apiLoaded = true
                resolve(true)
            })

        },
        $initMap(center, locations) {
            this.markers = [];
            this.markersInfo = [];
            var bounds = new google.maps.LatLngBounds();
            const mapEl = document.getElementById('locations-map');
                this.map = new google.maps.Map(mapEl,{
                    center: { lat: center.lat, lng: center.lng },
                    zoom: locations.length ? 6 : 6,
                    styles: [
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ],
                    disableDefaultUI: true
                });
            $('#locations-map').attr('data-center-lat', center.lat);
            $('#locations-map').attr('data-center-lng', center.lng);

            locations.forEach((location, index) => {
                var id = index;
                if (!location.lat) { return }
                var markerInfo = new google.maps.InfoWindow({
                    content: '<div class="markerInfo">' +
                        '<img class="mb-2" src="'+ location.image +'" width="30px" height="30px" />' +
                        '<div class="markerInfo-sub mb-2"><b>' + location.name + '</b></div>' +
                        '<div class="markerInfo-tit">Ubicación</div>' +
                        '<div class="markerInfo-vals">' +
                        '<div>' +
                        '<b>' + location.address + '</b>' +
                        '<div class="d-flex justify-content-center p-2">' +
                        '<a href="https://app.cluber.es/p/' + location.shortname + '" class="btn btn-md btn-primary" target="_blank">Acceder</a>' +
                        '</div>' +
                        '</div>' +
                        '</div>'
                });
                let newMarkPosition = new google.maps.LatLng(location.lat, location.lng);
                if (this.markers.length) {
                    this.markers.forEach(existingMarker => {
                        if (existingMarker.getPosition().equals(newMarkPosition)) {
                            const newLat = newMarkPosition.lat() + (Math.random() -.5) / 5000;// * (Math.random() * (max - min) + min);
                            const newLng = newMarkPosition.lng() + (Math.random() -.5) / 5000;// * (Math.random() * (max - min) + min);
                            newMarkPosition = new google.maps.LatLng(newLat,newLng);
                        }
                    })
                }
                const marker = new google.maps.Marker({
                    map: this.map,
                    position: newMarkPosition,
                    id: location.id,
                    icon: {
                        url: this.locationActiveIcon,
                        // This marker is 23 pixels wide by 33 pixels high.
                        size: new google.maps.Size(23, 33),
                        // The origin for this image is (0, 0).
                        origin: new google.maps.Point(0, 0),
                        // The anchor for this image is the base of the flagpole at (11, 33).
                        anchor: new google.maps.Point(11, 33)
                    }
                });
                bounds.extend(marker.position);
                this.prevInfoWindow = false;
                marker.addListener('click', (id) => {
                    if (this.prevInfoWindow) {
                        this.prevInfoWindow.close()
                    }
                    markerInfo.open(this.map, marker);
                    this.prevInfoWindow = markerInfo;
                    this.$emit('poiSelected', marker.id)
                });
                this.markersInfo.push(markerInfo);
                this.markers.push(marker);
            });
            if (locations.length) {
                this.map.center = bounds.getCenter();
                this.map.fitBounds(bounds);
            }

            // clusters
            const clusters = new MarkerClusterer(this.map, this.markers, {
                styles: [{
                    width: 38,
                    height: 38,
                    url: this.locationClusterIcon,
                    fontFamily: 'Titillium Web',
                    fontWeight: 'bold',
                    textColor: 'white',
                    textSize: 16,
                }],
                maxZoom: 16
            });
        },
        centerOnClub (clubId) {
            if (this.prevInfoWindow) {
                this.prevInfoWindow.close()
            }
            const targetMarkerIndex = this.markers.findIndex(marker => marker.id == clubId);
            const targetMarker = this.markers[targetMarkerIndex];
            const infoWindow = this.markersInfo[targetMarkerIndex];

            infoWindow.open(this.map, targetMarker);
            this.map.setCenter(targetMarker.position);
            this.map.setZoom(20);
            this.prevInfoWindow = infoWindow;
        }
    }
}
