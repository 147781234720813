<template>
    <div>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <div class="photo-container">
                    <img src="img/imago.png" alt="" />
                </div>
                <h1 class="title text-black">Servicio de Patrocinadores</h1>
                <h2 class="text-black-50">¡Tus patrocinadores te recibirán con los brazos abiertos!</h2>
            </div>
        </div>
        <div class="section">
            <div class="container pt-5">
                <div class="button-container">
                    <a href="https://app.cluber.es/register" class="btn btn-secondary btn-round btn-xl">Probar</a>
                </div>

                <div class="row m-auto col-md-6 col-xl-12">
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Las miles de visitas de tu club en Siguetuliga le interesan a los anunciantes.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Al fin tienes algo que ofrecerles para pedir su colaboración.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Además de asociar su imagen a tu club, les puedes mandar clientes a su web</strong>
                    </h4>
                </div>

                <div class="d-flex flex-row justify-content-center m-5">
                    <iframe
                            class="page-header-image mt-5"
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/365585893"
                            width="640" height="360"
                            frameborder="0" allowfullscreen>

                    </iframe>
                </div>

                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">¿Cómo funciona?</h2>
                </div>
                <div class="description text-left d-flex flex-wrap mb-5">
                    <ol class="col-md-12">
                        <li>Siguetuliga es una red social con más de 3 millones de usuarios. Eso genera miles de visitas
                            en las páginas de tu club.</li>
                        <li>Allí donde aparece tu club puedes poner a tus patrocinadores y cobrarles lo que estimes
                            oportuno. No hay límite de patrocinadores, puedes poner los que quieras y se irán rotando.
                        </li>
                        <li>Desde la imagen que pongas puedes situar también un enlace para que, al pinchar, el usuario
                            vaya a la web del anunciante, su Facebook…</li>
                        <li>El patrocinador estará encantado, ya que lo conocerá la gente que esté interesada en tu
                            club. ¿Cuánto querrá pagar para que lo vean y visiten miles de posibles clientes?</li>
                    </ol>
                </div>
                <!-- End Section -->


                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">Desde ahora podrás...</h2>
                </div>
                <div class="description text-left d-flex flex-wrap">
                    <ul class="col-md-12 col-xl-6">
                        <li>Subir un número ilimitado de patrocinadores a las páginas de tu club.</li>
                        <li>Saber cuántas visitas tienen tus patrocinadores cada mes, para así poder informarlos.</li>
                        <li>Colocar un enlace en cada imagen para enviar visitas a sus webs.</li>
                    </ul>
                    <ul class="col-md-12 col-xl-6">
                        <li>Elegir qué patrocinadores salen más veces que otros, en función de su aportación.</li>
                        <li>Modificar los anunciantes que quieres mostrar en cualquier momento.</li>
                        <li>Descargar un dosier personalizado del club con el que convencer a los patrocinadores.</li>
                    </ul>
                </div>
                <!-- End Section -->


                <faq-section :faqs="faqs"></faq-section>

                <contact-anchor/>

            </div>
        </div>
    </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client'
import FaqSection from "./components/FaqSection";
import ContactAnchor from "./components/ContactAnchor";

const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
})

export default {
    name: 'sponsors',
    components: {ContactAnchor, FaqSection},
    bodyClass: 'profile-page',

    data () {
        return {
            loading: true,
            faqs: null,
        }
    },

    mounted () {
        this.fetchData()
            .then(({faqs}) => {
                this.faqs = faqs
                this.loading = false
            })
    },

    methods: {
        async fetchData () {
            this.loading = true
            const parentsResponse = await api.get('cdn/stories/patrocinadores')
            return { faqs: parentsResponse.data.story.content['Faqs'] }
        }
    }
};
</script>
<style></style>
