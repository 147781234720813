<template>
    <div v-if="loading" class="section mt-5 pt-5">
        <div class="container d-flex flex-column">
            <SpinnerLoader />
        </div>
    </div>
    <div v-else>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <h1 class="title text-info text-left text-underline">{{ post.content.title }}</h1>
            </div>
        </div>
        <article class="blog-article">
            <div class="container flex-column align-items-center">
                <img
                    v-if="post.content.main_image"
                    class="post-page__image"
                    :src="scaledImage"
                    loading="lazy"
                    :alt="post.content.title"
                >
                <section class="d-flex align-items-center flex-wrap text-gray text-medium-large mb-5">
                    <img
                        class="mr-4" style="display: inline-block; height: 60px; width: auto"
                        :src="this.postAuthor.avatar"
                    >
                    <div class="d-flex flex-column justify-content-end">
                        <div class="mb-2">{{ `Publicado el ${formattedDate}` }}</div>
                        <div class="text-bold text-medium-large">{{ this.postAuthor.name }}</div>
                    </div>
                </section>
                <div v-html="post.content.body.html" class="post-page__content"></div>
                <section v-if="categories.length" class="mt-3 mb-5 d-flex align-items-center">
                    <span class="text-bold">Categorías: </span>
                    <router-link
                        v-for="category in categories"
                        :key="category"
                        class="post-tag"
                        :to="{name: 'categories', params: {slug: category}}"
                    >{{ category }}</router-link>
                </section>
                <social-sharing :url="this.postUrl"
                                :title="this.postTitle"
                                :description="this.postDescription"
                                :quote="this.postDescription"
                                hashtags="cluber"
                                twitter-user="cluber_app"
                                inline-template>
                    <div class="d-flex justify-content-center justify-content-xl-end flex-wrap align-items-center ml-auto pl-2 pr-2 bg-light-gray">
                        <span class="mr-4 text-bold text-medium-large d-block p-4">¿Te parece interesante? ¡Compártelo!</span>
                        <div class="d-flex flex-wrap justify-content-center">
                            <network network="email" class="btn btn-transparent btn-sm btn-github">
                                <font-awesome-icon icon="envelope" size="2x" title="Comparte por correo"/>
                            </network>
                            <network network="facebook" class="btn btn-transparent btn-sm btn-facebook">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" size="2x" title="Comparte en Faceebook"/>
                            </network>
                            <network network="twitter" class="btn btn-transparent btn-sm btn-twitter">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" size="2x" title="Comparte en Twitter"/>
                            </network>
                            <network network="linkedin" class="btn btn-transparent btn-sm btn-linkedin">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" size="2x" title="Comparte en Linkedin"/>
                            </network>
                            <network network="telegram" class="btn btn-transparent btn-sm btn-telegram">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'telegram' }" size="2x" title="Comparte por Telegram"/>
                            </network>
                            <network network="whatsapp" class="btn btn-transparent btn-sm btn-whatsapp">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" size="2x" title="Comparte por Whatsapp"/>
                            </network>
                        </div>
                    </div>
                </social-sharing>

                <section v-if="relatedArticles">
                    <h3 class="pt-5 pb-0">También te puede interesar</h3>
                    <featured-posts-section class="p-0" :posts="relatedArticles" :show-all="true" :compact="true"/>
                </section>
            </div>
        </article>
    </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client'
import Vue from 'vue'
import SpinnerLoader from "../components/SpinnerLoader";
import FeaturedPostsSection from "./components/featuredPostsSection";


const DEFAULT_AUTHOR = 'Cluber';
const DEFAULT_AUTHOR_AVATAR = 'https://a.storyblok.com/f/64023/x/000a4086f6/cluber_player.svg';

const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
});

export default {
    name: 'BlogPost',
    bodyClass: 'post-page',
    components: { FeaturedPostsSection, SpinnerLoader },
    data () {
        return {
            loading: true,
            post: null,
            author: null,
            requestedPath: null,
            relatedArticles: null
        }
    },

    mounted () {
        this.requestedPath = this.$route.path;
        this.fetchData(this.requestedPath)
            .then(({blogPost}) => {
                this.post = blogPost;
                if (blogPost.content.author) {
                    this.fetchAuthor().then(({author}) => {
                        this.author = author[0];
                        this.loading = false;
                    });
                } else {
                    this.loading = false
                }
                this.fetchRelatedArticles()
                    .then(({relatedArticles}) => {
                        this.relatedArticles = relatedArticles
                    })
            });
    },

    metaInfo () {
        return {
          meta: [
              { property: 'og:title', content: this.postTitle },
              { property: 'og:description', content: this.postDescription },
              { property: 'og:image',  itemprop: 'image', content: `https://${this.postImage}` },
              { property: 'og:url', content: `${location.protocol}//${window.location.hostname}${this.$route.fullPath}` },
              { property: 'og:site_name', content: 'Cluber - Impulsamos Clubes Deportivos' },
              { property: 'og:type', content: 'website' },
              { property: 'og:updated_time', content: this.postTimestamp },
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:image:alt', content: this.postDescription },
              { name: 'twitter:site', content: '@cluber_app' },
          ]
        }
    },

    computed: {
        formattedDate () {
          const publishDate = this.post && new Date(this.post.first_published_at);
          return `${publishDate.getDate()}/${publishDate.getMonth() + 1}/${publishDate.getFullYear()}`
        },
        categories () {
          return this.post && this.post.tag_list.filter(tag => tag !== 'featured')
        },
        postTitle () {
            return this.post && this.post.content.title
        },
        postDescription () {
            return this.post && this.post.content.summary
        },
        postImage () {
            return this.post && this.$transformImage(this.post.content.main_image, '0x200').substr(2)
        },
        postTimestamp () {
            if (!this.post) return false;
            const pubDate = new Date(this.post.published_at);
            return this.post && pubDate.toISOString()
        },
        postUrl () {
            return `${location.protocol}//${window.location.hostname}${this.$route.fullPath}`
        },
        postId () {
          return this.post && this.post.id
        },
        postAuthor () {
            if (this.post && this.post.content.author) {
                return {
                    name: this.author.name,
                    avatar: this.author.content.avatar
                }
            } else {
                return {
                    name: DEFAULT_AUTHOR,
                    avatar: DEFAULT_AUTHOR_AVATAR
                }
            }
        },
        scaledImage () {
            return this.$transformImage(this.post.content.main_image, '1250x0')
        }
    },

    methods: {
        async fetchData (requestedPath) {
            this.loading = true;
            const blogResponse = await api.get(`cdn/stories${requestedPath}`);
            return { blogPost: blogResponse.data.story }
        },
        async fetchAuthor () {
            this.loading = true;
            let options = {
                starts_with: 'authors/',
                by_uuids: this.post.content.author
            };
            const blogResponse = await api.get('cdn/stories', options);
            return { author: blogResponse.data.stories }
        },
        async fetchRelatedArticles () {
            let options = {
                starts_with: 'blog/',
                per_page: 3,
                sort_by: 'first_published_at:desc',
                excluding_ids: this.postId,
                with_tag: this.categories,
                filter_query: {
                  "hide_from_list": {
                    "is": false
                  }
                }
            };
            const blogResponse = await api.get('cdn/stories', options);
            return { relatedArticles: blogResponse.data.stories }
        },
    }
};
</script>
<style></style>
