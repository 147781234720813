<template>
  <div class="d-flex flex-column align-items-center cb-digitalkit" style="margin-top: 7rem">
    <div class="mx-auto col-9 col-lg-8 col-xl-6">
      <div>
        <h3 class="title text-secondary text-center">Digitalizamos clubes</h3>
        <p>En Cluber llevamos desde 2019 ayudando a clubes a digitalizar sus procesos. Ahora, con el **Programa Kit Digital**, existente gracias a los fondos europeos Next Generation EU, dentro del Plan de Recuperación, Transformación y Resilencia, dirigido a pymes de hasta 49 trabajadores y autónomos que cumplan con los requisitos marcados del Gobierno, podremos ponértelo todavía más fácil.</p>
      <p>
        El Programa divide a las entidades en 3 segmentos:
      </p>
      <ul class="mb-4">
        <li>
          - Segmento I (12.000 €): entidades de entre 10 y menos de 50 empleados
        </li>
        <li>
          - Segmento II (6.000 €): entidades de entre 3 y menos de 10 empleados
        </li>
        <li>
          - Segmento III (2.000 €): entidades hasta 3 empleados
        </li>
      </ul>
      <p>Estas empresas podrán recibir hasta un máximo de 12.000€ en ayudas que tendrán que utilizar en la digitalización de sus procesos/actividades eligiendo las soluciones digitalizadoras del Kit Digital que mejor se adapten a sus necesidades.
      Para poder acceder al Kit Digital deberán hacerlo a través de uno o varios agentes digitalizadores que podrán encontrar en la página AceleraPyme. Esto último es importante porque las empresas digitalizadoras son las que acompañarán en todo el proceso de solicitud a las entidades.</p>
      <p>A continuación indicamos los servicios que puede ofrecerte Cluber dentro de las categorías de soluciones de digitalización propuestas por el Programa:</p>
      </div>
      <spinner-loader v-if="isLoading" />
      <template v-else>
        <div class="row">
          <div
            v-for="(service, index) in stories"
            :key="index"
            class="col-12 col-md-6 mb-5 px-4"
          >
              <h3 class="title text-secondary text-center">
                {{ service.content.name }}
              </h3>
              <img class="col-12 col-sm-6 mb-4 d-sm-block mx-sm-auto mb-sm-5" :src="service.content.image.filename" />
              <span
                v-html="service.content.body.html"
                class="d-block"
              >
              </span>
              <div class="d-flex align-items-end justify-content-between">
                <a
                  class="btn btn-primary m-0"
                  href="https://forms.zohopublic.eu/cluber/form/SolicituddeinformacinKitDigital/formperma/4T4oPtwcC1qBp28i61jFa2EzYcWILz6MGeEAisZbzlg" target="_blank"
                >
                  Solicitar info.
                </a>
                <span
                  class="text-capitalize h3 title m-0 text-right d-block"
                >
                  {{ service.content.price }}
                </span>
              </div>
              <hr/>
            </div>
        </div>
      </template>
    </div>
    <footer>
      <img class="mx-auto d-block col-9 col-lg-8 col-xl-6" src="img/digitalkit/CONBANDERA_KITDIGITAL.png" alt="digitalizadores" />
    </footer>
  </div>
</template>

<script>
import StoryblokClient from "storyblok-js-client";
import SpinnerLoader from "../components/SpinnerLoader.vue";

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export default {
  components: {
    SpinnerLoader,
  },
  mixins: [],
  data() {
    return {
      isLoading: true,
      stories: [],
    };
  },
  mounted() {
    this.getDigitalKitServices();
  },
  methods: {
    getDigitalKitServices() {
      this.isLoading = true
      const options = {
          starts_with: "kitdigital/",
          sort_by: "content.order:asc",
        };
      api.get('cdn/stories/', options)
      .then(response => {
        this.isLoading = false;
        this.stories = response.data.stories;
      })
      .catch(error => {
        this.isLoading = false;
        this.stories = [];
        throw new Error(error)
      })
    },
  },
  computed: {
    getOnlyFirstStory() {
      return this.stories.filter((item, index) => index === 0)
    }
  }
};
</script>

<style>
.cb-digitalkit ul {
  padding: 0;
}
</style>