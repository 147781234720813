<template>
  <ul>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
      <span>Acceso web y app</span>
    </li>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
      <span>Buscador y web Cluber</span>
    </li>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
      <span>Tienda online</span>
    </li>
    <li>
      <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
      <span>Inscripción y cobro online de campus</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PricingFeaturesStarter"
}
</script>
