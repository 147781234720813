<template>
    <div>
        <div class="page-header clear-filter" filter-color="greenTogreen">
            <div class="container">
                <div class="photo-container">
                    <img src="img/imago.png" alt="" />
                </div>
                <h1 class="title text-black">Servicio de Socios</h1>
                <h2 class="text-black-50">¡Conseguir y renovar socios ya no es un problema!</h2>
            </div>
        </div>
        <div class="section">
            <div class="container pt-5">
                <div class="button-container mb-5">
                    <a href="https://app.cluber.es/register" class="btn btn-secondary btn-round btn-xl">
                        Probar
                    </a>
                </div>

                <div class="row m-auto col-md-6 col-xl-12">
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>No tendrás que perseguir nunca más a los socios para renovarlos.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>No necesitas que vayan por el campo para contactar con ellos.</strong>
                    </h4>
                    <h4 class="col-md-12 col-xl-4 blockquote">
                        <strong>Enviando un whatsapp haces un socio... ¡para toda la vida!</strong>
                    </h4>
                </div>

                <div class="d-flex flex-row justify-content-center m-5">
                    <iframe
                            class="page-header-image mt-5"
                            src="https://player.vimeo.com/video/365585619"
                            width="640" height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen>
                    </iframe>
                </div>

                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">¿Cómo funciona?</h2>
                </div>
                <div class="description text-left d-flex flex-wrap mb-5">
                    <ol class="col-md-12">
                        <li>Tienes un listado con todos los socios, si cobras en mano, lo marcas como pagado.</li>
                        <li>A los que no han pagado o no has podido ver les puedes enviar un whatsapp desde la propia
                            herramienta. Desde ahí, el socio puede pagar al momento con tarjeta.</li>
                        <li>Ya tienes todo el dinero sin necesidad de perseguirlos... Ahora, a captar nuevos. Comparte
                            un enlace en RRSS o envíalo por Whatsapp a jugadores, familiares... NUEVOS SOCIOS A LA
                            VISTA!</li>
                        <li>Y el próximo año... todos renovados con un click!!</li>
                    </ol>
                </div>
                <!-- End Section -->

                <!-- Init Section -->
                <div class="d-flex flex-row justify-content-lg-start">
                    <h2 class="title text-underline">Desde ahora podrás...</h2>
                </div>
                <div class="description text-left d-flex flex-wrap">
                    <ul class="col-md-12 col-xl-6">
                        <li>Gestionar diferentes cuotas (diferentes modalidades, precios...)</li>
                        <li>Automatizar pagos recurrentes con tarjeta y/o domiciliación bancaria</li>
                        <li>Enviar y recibir comunicaciones de tus socios</li>
                        <li>Dar acceso a una sección privada a tus socios para consultar novedades, horarios de partidos, resultados o mostrar su carné virtual</li>
                    </ul>
                    <ul class="col-md-12 col-xl-6">
                        <li>Personalizar el formulario de alta</li>
                        <li>Socios relacionados (socio familiar, socio protector...)</li>
                        <li>Llevar la gestión de pagos en efectivo o en cuenta</li>
                        <li>Invitar a la venta de productos del club o a participar en actividades</li>
                        <li>Posibilidad de renovar a tus socios automáticamente, previa notificación</li>
                    </ul>
                </div>
                <!-- End Section -->

                <faq-section :faqs="faqs"></faq-section>

                <contact-anchor/>

            </div>
        </div>
    </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client'
import FaqSection from "./components/FaqSection";
import ContactAnchor from "./components/ContactAnchor";

const api = new StoryblokClient({
    accessToken: process.env.VUE_APP_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
})

export default {
    name: 'Members',
    components: {ContactAnchor, FaqSection},
    bodyClass: 'profile-page',

    data () {
        return {
            loading: true,
            faqs: null,
        }
    },

    mounted () {
        this.fetchData()
            .then(({faqs}) => {
                this.faqs = faqs
                this.loading = false
            })
    },

    methods: {
        async fetchData () {
            this.loading = true
            const parentsResponse = await api.get('cdn/stories/socios')
            return { faqs: parentsResponse.data.story.content['Faqs'] }
        }
    }
};
</script>
