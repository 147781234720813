<template>
  <div
    class="section section-signup"
    style="
      background-image: url('img/header-grass.jpg');
      background-size: cover;
      background-position: center;
      min-height: 700px;
      margin: 0 auto;
    "
  >
    <div style="padding-top: 200px" id="contacto">
      <div class="col-12 col-md-6 m-auto">
        <CluberForm prefix="PROD"/>
      </div>
    </div>
  </div>
</template>
<script>
import CluberForm from "@/pages/components/CluberForm";

export default {
  components: {
    CluberForm,
  },
};
</script>
<style>
.card-signup img {
  max-width: 80%;
}
</style>
