<template>
  <div
    v-if="loadingPage"
    :style="{
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      height: '100vh'
    }"
  >
    <SpinnerLoader />
  </div>
  <div
    v-else
    class="d-flex flex-column align-items-center"
  >
    <div
      class="page-header clear-filter page-header--success flex order-0"
      filter-color="greenTogreen"
    >
      <div class=" container mt-4 d-flex justify-content-lg-between flex-column flex-lg-row align-items-lg-start align-items-center">
        <SpinnerLoader v-if="loadingCounters"></SpinnerLoader>
        <div v-else class="text-center mt-4 mt-lg-0 metric-size">
          <img src="/img/vector/icons/icon-user.svg" style="height: 7.5em" />
          <h2 class="title pt-2 mb-0" style="font-size: 4.5em">{{ counters.users }}</h2>
          <h2 class="title pt-1">personas</h2>
        </div>
        <div class="text-center mt-4 mt-lg-0 metric-size">
          <img src="/img/vector/icons/shield.svg" style="height: 7.5em" />
          <h2 class="title pt-2 mb-0" style="font-size: 4.5em">{{ counters.teams }}</h2>
          <h2 class="title pt-1">equipos</h2>
        </div>
        <div class="text-center mt-4 mt-lg-0 metric-size">
          <img src="/img/vector/icons/runningman.png" style="height: 7.5em" />
          <h2 class="title pt-2 mb-0" style="font-size: 4.5em">{{ counters.sportsCount }}</h2>
          <h2 class="title pt-1">disciplinas deportivas</h2>
        </div>
      </div>
    </div>

    <!-- Club logos -->
    <div
      :class="[
        'container d-flex flex-column align-items-center',
        'order-2'
      ]"
    >
      <SpinnerLoader v-if="loadingClubs" />
      <h3 v-else-if="clubs.length === 0">
        No hay clubes disponibles en estos momentos.
      </h3>
      <div v-else>
        <h2 class="title text-center">Nos avalan</h2>
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <ClubLogo
            v-for="federation in federationLogos"
            :key="federation.id"
            :clubLogo="federation.logo"
            :clubUrl="federation.url"
            :clubName="federation.name"
          />
        </div>
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <ClubLogo
            v-for="club in clubLogos"
            :key="club.id"
            :clubLogo="club.logo"
            :clubUrl="club.url"
            :clubName="club.name"
          />
        </div>
      </div>
    </div>

    <!-- Chart section -->
    <div class="container d-flex flex-column align-items-center mb-5 mt-5 order-3">
      <h2 class="title">Cómo ayudamos a nuestros clientes</h2>
      <div class="col-xl-10 col-sm-11 col-12">
        <div id="chart-grid" class="mx-3">
          <template v-for="(item, index) in chartData">
            <div
              class="text-right"
              :key="item.name"
            >
              <h5 class="text-bold m-2 chart-text">
                <span
                  v-if="index <= 1"
                  class="chartbar-highlight-icon"
                >
                  ✪
                </span>
                {{ item.name }}
              </h5>
            </div>
            <div class="px-0 py-1" :key="item.name + '-progress'">
              <div
                class="h-100 d-flex align-items-center"
                :style="{
                  width: item.rate,
                }"
              >
                <div
                  :class="[
                    { 'chartbar-highlight': index <= 1 },
                    'chartBar px-2 d-flex align-items-center'
                  ]"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Blog section -->
    <div
      v-if="!extraRouteParam"
      class="mb-5 container d-flex flex-column align-items-center order-4"
    >
      <h2 class="title">Conoce algunas de nuestras historias de éxito</h2>
      <SpinnerLoader v-if="loadingBlogEntries" />
      <h3 v-else-if="blogEntries.length === 0">
        No hay publicaciones disponibles en estos momentos.
      </h3>
      <div class="d-flex flex-wrap justify-content-center" v-else>
        <div v-for="post in blogEntries" :key="post.id" class="col-md-6 col-xl-4">
          <router-link
            :to="{ name: 'blogPost', params: { slug: post.slug } }"
            class="no-underline"
          >
            <Card type="post">
              <template slot="image">
                <img
                  :src="getPostThumbnail(post)"
                  alt="Imagen cabecera del artículo"
                />
              </template>
              <template slot="header">
                <h3 class="card-title title-up">{{ post.content.title }}</h3>
              </template>
              <template slot="raw-content">
                <span
                  v-html="post.content.summary"
                  class="card-body text-ellipsis"
                />
                <router-link
                  class="btn btn-primary btn-round"
                  :to="{ name: 'blogPost', params: { slug: post.slug } }"
                >
                  Leer artículo
                </router-link>
              </template>
            </Card>
          </router-link>
        </div>
      </div>
      <router-link :to="{ name: 'categories', params: { slug: 'casos de éxito' } }">
        <span class="btn btn-round btn-simple btn-primary btn-sm">
          Ver más historias
        </span>
      </router-link>
    </div>

    <!-- Club contact form -->
    <div
      id="club-contact-form"
      :class="[
        'py-5 container-fluid d-flex flex-column align-items-center',
        isEnclosedLandingPage ? 'order-1' : 'order-4',
      ]"
      :style="{
        'background-image': `url(${extraParamsImage})`,
        'background-size': 'cover',
        'background-position': 'center',
      }"
    >
      <div class="col-12 col-md-6">
        <CluberForm
          v-if="!extraRouteParam"
          prefix="SUC"
        />     
        <div
          v-else
          class="pipedriveWebForms bg-white rounded p-5 d-flex justify-content-center"
          data-pd-webforms="https://forms.zohopublic.eu/cluber/form/SolicituddeinformacinKitDigital/formperma/4T4oPtwcC1qBp28i61jFa2EzYcWILz6MGeEAisZbzlg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client';
import SpinnerLoader from '../components/SpinnerLoader.vue';
import ClubLogo from '../components/ClubLogo.vue';
import { Card, FormGroupInput, Button } from '@/components';
import pipedriveMixin from '@/mixins/pipedriveMixin';
import { loadScript } from '@/utils/scriptLoader';
import CluberForm from '@/pages/components/CluberForm';
import LandingPage from '@/mixins/LandingPage';

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export default {
  components: {
    CluberForm,
    SpinnerLoader,
    Card,
    ClubLogo,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  mixins: [
    pipedriveMixin,
    LandingPage,
  ],
  data() {
    return {      
      // Form
      name: '',
      mail: '',
      phone: '',
      club: '',
      message: '',
      termsChecked: false,

      // Storyblok
      loadingClubs: true,
      loadingBlogEntries: true,
      loadingCounters: true,
      loadingPage: true,
      blogEntries: [],
      clubs: [],
      clubLogos: [],
      federations: [],
      federationLogos: [],
      
      // Chart data
      chartData: [
        { name: 'Reducción de impagos', rate: '97.68%' },
        { name: 'Patrocinio', rate: '94.06%' },
        { name: 'Inscripción deportistas y socios', rate: '90.09%' },
        { name: 'Gestión automática de cuotas', rate: '88.89%' },
        { name: 'Tienda online', rate: '70.65%' },
        { name: 'Comunicaciones', rate: '65.38%' },
        { name: 'Entradas y control de accesos', rate: '63.25%' },
      ],
      counters: {
        users: '+270.000',
        teams: '+5.000',
        sportsCount: '55'
      },

      // Route params for multiple sports
      extraRouteParam: '',
      extraParamsForm: '',
      extraParamsImage: '/img/header-grass.jpg',

      scriptLoaded: false,
    };
  },
  async mounted() {
    await this.getAcceptedKindOfSports();
    // Detect URL param/query
    this.detectExtraRouteParam();

    this.fetchSuccessCounters();

    // Clubs data
    await this.fetchClubs();
    this.setLogosByUrlParams(this.clubs, 'clubLogos');

    // Federations data
    await this.fetchFederations()
    this.setLogosByUrlParams(this.federations, 'federationLogos');

    // Set proper content based on the previous step
    if (!this.extraRouteParam) {
      this.fetchBlogEntries();
      this.loadingPage = false;
    } else {
      this.fetchExtraParamData()
      this.loadingPage = false;
    }
  },
  watch: {
    loadingPage(current) {
      setTimeout(() => {
        if (!current && !this.scriptLoaded) {
          loadScript('https://webforms.pipedrive.com/f/loader');
          this.scriptLoaded = true;
        }
      }, 200);
    }
  },
  methods: {
    fetchExtraParamData() {
      api.get(`cdn/stories/parametros-casos-de-exito/${this.extraRouteParam}`)
      .then(({ data }) => {
        this.extraParamsForm = data.story.content.form;
        this.extraParamsImage = data.story.content.image.filename;
        if (this.$route.query.pipedriveForm !== this.extraParamsForm) {
          this.$router.push({
            query: {
              ...this.$route.query,
              pipedriveForm: this.extraParamsForm,
            }
          })
        }

      })
      .catch(error => {
        this.extraParamsForm = '';
        this.extraParamsImage = '';
      })
    },
    async fetchFederations() {
      const options = {
        starts_with: 'federations/',
        per_page: 100,
      };
      await api.get('cdn/stories', options).then(({ data }) => {
        this.federations = data.stories;
      }).catch(error => {
        this.federations = [];
        throw new Error(error);
      });
    },
    async fetchClubs() {
      const options = {
        starts_with: 'clubs/',
        per_page: 100,
      };
      await api.get('cdn/stories', options)
        .then(({ data }) => {
          this.clubs = data.stories;
          this.loadingClubs = false;
        })
        .catch(error => {
          this.clubs = [];
          throw new Error(error);
        });
    },
    async fetchBlogEntries() {
      const options = {
        starts_with: 'blog/',
        per_page: 3,
        page: 1,
        sort_by: 'first_published_at:desc',
        with_tag: 'casos de éxito',
      };

      await api.get('cdn/stories', options).then(({ data }) => {
        this.blogEntries = data.stories;
        this.loadingBlogEntries = false;
      });
    },
    setLogosByUrlParams(source, target) {
      // If any param is set in URL, logos will be filtered looking for the label set in
      // storyblok, otherwise the ones with 'featured' tag will be shown
      // I.E: /success-stories?c=voley will only show the ones with tag voley
      const filteredBySport = source.filter(item => {
        if (!!this.extraRouteParam) {
          return item.content.label.includes(this.extraRouteParam)
        }
        return item.content.label.includes('featured')
      })

      this[target] = filteredBySport.map(item => ({
        logo: item.content.image.filename,
        id: item.id,
        url: '',
        name: item.content.name,
      }));
    },
    fetchSuccessCounters () {
      this.loadingCounters = true
      api.get('cdn/stories/casos-de-exito')
      .then(response => {
        const countersData = response.data.story.content.body[0]
        this.counters.users = countersData.users_count
        this.counters.teams = countersData.teams_count
        this.counters.sportsCount = countersData.sports_count
        this.loadingCounters = false
      })
      .catch(error => {
        this.loadingCounters = false
        throw new Error(error)
      })
    },
    getPostThumbnail(post) {
      if (post.content.main_image) {
        return this.$transformImage(post.content.main_image, '0x200');
      } else {
        return '/img/default-article-thumbnail.jpg';
      }
    },
    detectExtraRouteParam() {
      // If any param or query is set on the URL, page should
      // change accordingly to the kind of sport
      const { params, query } = this.$route;
      const kindOfSport = params.kindofsport || query.c;

      // Filter unwanted/unsupported sports to avoid issues
      const isAllowedSport = this.allowedSports.includes(kindOfSport);
      this.extraRouteParam = isAllowedSport
        ? kindOfSport
        : ''; // Empty string uses 'Fotball' template
    },
  },
};
</script>

<style scoped>
.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.topBar {
  border-top: 2px solid rgb(43, 68, 93);
}

#chart-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
}

@media (min-width: 992px) {
  .metric-size {
    width: 30% !important;
  }
}
@media (max-width: 992px) {
  #chart-grid {
    grid-template-columns: auto;
  }
  .chart-text {
    text-align: left;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0.5em !important;
  }

  .topBar {
    border-top-width: 0px;
  }
}
.chartBar {
  height: 100%;
  max-height: 2em;
  min-height: 1.5em;
  background-color: #092643;
  animation: load 1.25s ease 0s normal forwards;
}

.chartbar-highlight {
  background-color: #0080FF !important;
}

.chartbar-highlight-icon {
  color: #0080FF;
  font-size: 1.3rem;
  position: relative;
  top: 2px;
}

.page-header--success {
  height: auto !important;
  width: 100%;
}

@keyframes load {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
