import StoryblokClient from 'storyblok-js-client';

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export default {
  data () {
    return {
      allowedSports: [],
    }
  },
  created() {
    this.getAcceptedKindOfSports()
  },
  methods: {
    async getAcceptedKindOfSports() {
      // Check which sports are set on storyblok meaning that they are allowed
      // as query or params for landings
      await api.get('cdn/stories', { starts_with: 'parametros', })
        .then(({ data }) => {
          data.stories.forEach(story => this.allowedSports.push(story.slug))
        }).catch(error => {
          throw new Error(error);
        });
    }
  },
  computed: {
    isEnclosedLandingPage() {
      const checkMatch = (object) => Object.values(object).some(item => this.allowedSports.includes(item));
      const isMatchingParam = checkMatch(this.$route.params);
      const isMatchingQuery = checkMatch(this.$route.query);
      const hasMatchingParamOrQuery = isMatchingParam || isMatchingQuery;

      return this.$route.meta.forceAvoidVisitLeaks || (this.$route.meta.avoidVisitLeaks && hasMatchingParamOrQuery);
    },
  },
}
