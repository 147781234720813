<template>
    <div class="container flex-row mb-4">
        <router-link
            :to="{name: 'blog', params: { reload: false }}"
            class="post-page__menu-item nav-link"
            :class="{'bg-info--opacity':(!$route.params.slug)}"
        >
            Todos
        </router-link>
        <router-link
            v-for="(tag, index) in categoryNamesList"
            :key="index"
            :to="{name: 'categories', params: {slug: tag}}"
            class="post-page__menu-item nav-link"
            :class="{'bg-info--opacity':($route.params.slug === tag)}"
        >
            {{ tag }}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "BlogCategoryMenu",
        props: {
            categoryNamesList: {
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/now-ui-kit/variables';
    .post-page__menu-item {
        color: $black-color;
        display: inline-flex;
        text-transform: uppercase;
        white-space: nowrap;
        &:hover {
            background-color: whitesmoke;
        }
        &.active {
            color: $primary-color !important;
        }
    }
</style>
