<template>
    <div class="min-vh-100">
        <div class="page-header clear-filter page-header--access" filter-color="greenTogreen">
            <div class="container">
                <div class="photo-container">
                    <img src="img/cluber_imago_2.1.jpg" alt="Logo de Cluber" />
                </div>
                <h1 class="title text-black">Gracias! Hemos recibido tu solicitud.</h1>
                <h2 class="text-black-50">En breve te contactaremos para explicarte cómo empezar a usar Control de Accesos de Cluber</h2>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FormSuccess',
    bodyClass: 'profile-page',
    data () {
        return {
        }
    }
};
</script>

<style scoped lang="scss">
    .photo-container img{
        height: 100% !important;
        object-fit: cover !important;
        overflow: hidden;
        width: 100% !important;
    }
</style>
