<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="d-flex justify-content-around align-items-center ml-5 mr-5">
      <nav class="flex-basis-50">
        <ul>
          <li>
            <a href="https://app.cluber.es" target="_blank" rel="noopener">
              Cluber
            </a>
          </li>
          <li>
            <a href="https://app.cluber.es/es/politica-privacidad/legal" target="_blank" rel="noopener">
              Aviso Legal
            </a>
          </li>
          <li>
            <a onclick="jQuery('body').ihavecookies({}, 'reinit')" class="link text-uppercase">
              Política de Cookies
            </a>
          </li>
          <li>
            <a href="https://app.cluber.es/es/politica-privacidad/privacy" target="_blank" rel="noopener">
              Política de Privacidad
            </a>
          </li>
          <li>
            <a href="https://app.cluber.es/es/politica-privacidad/clubs" target="_blank" rel="noopener">
              Condiciones Generales
            </a>
          </li>
        </ul>
      </nav>
      <section
        v-if="!isEnclosedLandingPage"
        class="d-flex ml-1 mr-1"
      >
        <a href="https://facebook.com/cluberapp" class="m-1" target="_blank" rel="noopener norefer">
          <span class="stl-icon stl-icon--facebook stl-icon--light"></span>
        </a>
        <a href="https://twitter.com/cluber_app" class="m-1" target="_blank" rel="noopener norefer">
          <span class="stl-icon stl-icon-big stl-icon--twitter"></span>
        </a>
      </section>
      <div class="copyright flex-basis-50">
        &copy; {{ year }}
        <a
          href="https:///www.cluber.es"
          target="_blank"
          rel="noopener"
        >
          Cluber
        </a>
        , Todos los derechos reservados
      </div>
    </div>
  </footer>
</template>

<script>
import LandingPageMixin from '@/mixins/LandingPage';
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  mixins: [
    LandingPageMixin
  ],
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
