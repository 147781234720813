<template>
    <div class="d-flex flex-column bg-primary pb-5">
        <div class="container">
            <div class="d-flex flex-row">
                <h2 class="title text-white text-underline text-underline--white">Cluber en los medios</h2>
            </div>
            <div class="row justify-content-lg-start">
                <div
                    v-for="(post, index) in news"
                    :key="index"
                    class="col-md-6 col-xl-4">
                    <a
                        :href="post.url"
                        target="_blank"
                        class="link-image"
                    >
                        <card class="card-post card--round-l">
                            <template slot="image">
                                <img :src="post.image" alt="Imagen cabecera del artículo" />
                            </template>
                            <template slot="header">
                                <h3 class="card-title title-up">{{ post.title }}</h3>
                            </template>
                            <template slot="raw-content">
                                <span class="card-body">{{ post.caption }}</span>
    <!--                            <a-->
    <!--                                class="btn btn-primary btn-round"-->
    <!--                                :href="post.url"-->
    <!--                                target="_blank"-->
    <!--                            >-->
    <!--                                Visitar enlace-->
    <!--                            </a>-->
                            </template>
                        </card>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from "../../components/Cards/Card";

export default {
    name: "AccessControlNews",
    components: {Card},
    data () {
        return {
            news: [
                {
                    image: '/img/news/1ufedega.png',
                    title: 'Xunta y UFEDEGA presentan una aplicación que facilita el control sobre los aforos de los espectáculos deportivos minimizando los riesgos',
                    caption: 'El secretario xeral para o Deporte, José Ramón Lete Lasa, presentó junto a la directiva de la Unión de Federaciones Deportivas Galegas, una aplicación digital para el control de aforo y accesos a los recintos deportivos, lo que supone "un nuevo paso en la lucha contra la Covid-19"',
                    url: 'https://deporte.xunta.gal/gl/novas-deporte-galego/xunta-e-ufedega-presentan-un-aplicacion-que-facilita-o-control-sobre-os-aforos-dos-espectaculos-deportivos-minimizando-os-riscos'
                },
                {
                    image: '/img/news/imagen2.png',
                    title: 'Las federaciones de Murcia estrenan la aplicación Cluber para controlar el acceso a sus eventos',
                    caption: 'La de Balonmano es la que la ha puesto en marcha en primer lugar en una jornada de tecnificación que se ha llevado a cabo en el CAR de Los Narejos',
                    url: 'https://murciaplaza.com/las-federaciones-estrenan-la-aplicacion-cluber-para-controlar-el-acceso-a-sus-eventos'
                },
                {
                    image: '/img/news/imagen3.png',
                    title: 'El regreso del público a los estadios podría estar en manos de la tecnología',
                    caption: 'Esta herramienta, permite que todo el proceso, desde la compra de las entradas, con la vinculación del carné de socio a un código QR o formulario de inscripción, hasta el control de acceso en el recinto, pueda realizarse sin contacto físico, minimizando los riesgos.',
                    url: 'https://www.galiciapress.es/texto-diario/mostrar/2204140/crean-aplicacion-digital-facilita-control-aforos-espectaculos-deportivos'
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
    .link-image{
        text-decoration: none !important;
    }
    .card-post {
        min-height: 470px;
    }
</style>
