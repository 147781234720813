<template>
    <div class="price-value price-value--mini price-value--light d-flex align-items-center justify-content-center">
        <span class="flex-shrink-0">{{ `${price}` }}</span>
        <i class="stl-icon stl-icon-small stl-icon--question" v-popover:pop-tax></i>
        <el-popover
                ref="pop-tax"
                popper-class="popover popover-dark"
                placement="top"
                width="500"
                trigger="hover"
        >
            <h3 class="popover-header">Comunidad Autón. Canarias</h3>
            <div class="popover-body">Para entidades de la Comunidad Autónoma de Canarias el servicio no está sujeto a aplicación del IVA español, de acuerdo con los criterios recogidos en la Directiva 2006/112/CE. En este caso, el cliente deberá declarar el IGIC (7%) del importe facturado</div>
        </el-popover>
    </div>
</template>

<script>
    import { Popover } from 'element-ui';
    export default {
        name: "PricingTaxPopover",
        components: {[Popover.name]: Popover},
        props: ['price']
    }
</script>

