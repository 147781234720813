<template>
  <div>
    <div>
      <input
          :required="true"
          class="mr-2 d-inline"
          type="checkbox"
          name="terms-and-conditions"
          id="terms-and-conditions"
          :value="termsAndConditionsLink"
          v-model="checked"
          @change="onInputChanged"
      />
      <label class="d-inline title text-gray text-small" for="terms-and-conditions">
        Acepto la <a :href="defaultLink" target="_blank">política de privacidad</a> y consiento expresamente el tratamiento de mis datos personales
      </label>
    </div>
    <div class=" mt-4 mb-4 text-gray text-left">
      <p class="text-small mb-1">
        Información Básica sobre Protección de Datos
      </p>
      <p class="text-small mb-1">
        <strong>Responsable:</strong> Cluber Siguetuliga, S.L. (Cluber)
      </p>
      <p class="text-small mb-1">
        <strong>Finalidad:</strong> Gestionar su solicitud de información o consulta
      </p>
      <p class="text-small mb-1">
        <strong>Derechos:</strong> Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional que puede consultar en la <a target="_blank" :href="defaultLink">Política de Privacidad</a>
      </p>
    </div>
  </div>
</template>

<script>
const DEFAULT_TERMS_LINK = 'https://app.cluber.es/es/politica-privacidad/privacy';
export default {
  name: "TermsAndConditions",
  data () {
    return {
      checked: false,
      loading: true,
      lastTermsLink: 'https://app.cluber.es/es/politica-privacidad/',
      defaultLink: DEFAULT_TERMS_LINK
    }
  },
  computed: {
    termsAndConditionsLink () {
      return this.lastTermsLink
    }
  },
  mounted () {
    this.getTerms();
  },
  methods: {
    getTerms () {
      fetch('https://app.cluber.es/api/terms_versions?type=managers')
          .then((response) => response.json())
          .then((json) => {
            const termsOrderedFromNewest = json.sort((a,b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0));
            const lastTermsPublished = termsOrderedFromNewest[0];
            this.lastTermsLink = `${this.lastTermsLink}${lastTermsPublished.id}`;
            this.loading = false
          })
          .catch(() => {
            this.lastTermsLink = this.defaultLink;
            this.loading = false
          })
    },
    onInputChanged (payload) {
      this.$emit('changed', this.checked)
    }
  }
}
</script>
