export default [
  {
    id: "d3b4f300-a710-11ed-ab3b-db6a839dabb8",
    name: "Patrocinio",
  },
  {
    id: "07da14f0-d371-11eb-932e-09ace64b30fa",
    name: "Inscripción deportistas",
  },
  {
    id: "0dffdf90-d371-11eb-8a38-932cc84bb0c6",
    name: "Captación de socios",
  },
  {
    id: "169a53b0-d371-11eb-bd9c-b3ae18d8cac2",
    name: "Gestión automática de cuotas",
  },
  {
    id: "1c833c60-d371-11eb-8f53-e5238c7bae23",
    name: "Control de accesos",
  },
  {
    id: "39ef95a0-d371-11eb-8a38-932cc84bb0c6",
    name: "Tienda online",
  },
  {
    id: "3e1d60d0-d371-11eb-9e7f-173e9330d6f6",
    name: "Otros",
  },
  {
    id: "276c46f0-d58b-11eb-8824-574371abbaa3",
    name: "Inscripciones campus, torneos...",
  },
  {
    id: "5552a7e0-407e-11ec-9b20-5b4ccdcc5262",
    name: "Demo",
  },
  {
    id: "5c702900-5292-11ec-848f-8dfc55eea87a",
    name: "Contacto Portal público",
  }
]