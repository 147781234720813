<template>
  <div  class="gray-block h-100">
    <div id="locations-map" style="height: 100%;width: 100%;" class="mb-30"></div>
  </div>
</template>

<script>
import MapsMixin from "../mixins/MapsMixin";
export default {
  name: "CMap",
  mixins: [MapsMixin],
  props: ['icon', 'locationData'],
  data () {
    return {
      center: {
        lat: 40.085015,
        lng: -4.096688,
      }
    }
  },
  computed: {
    locationsLoaded () {
      const locations = this.locationData && this.locationData.map(item => {
        return {
          lat: item.coordinates.lat,
          lng: item.coordinates.lng,
          name: item.name,
          address: item.clubAddress,
          image: item.image,
          id: item.id,
          shortname: item.shortname
        }
      })
      return locations
    }
  },
  watch: {
    locationsLoaded (value) {
      setTimeout(() => {
        this.$initMap(this.center, value);
      }, 500)
    }
  },
  created () {
    this.importScript()
    .then(() => {
      setTimeout(() => {
        this.$initMap(this.center, this.locationsLoaded)
      }, 1000)
    })
  }
}
</script>

<style scoped>

</style>
