<template>
    <card class="card-pricing  card--round-xl" header-classes="text-center">
        <template slot="header">
            <h3 class="card-title title-up">
                <span class="tag tag--test">{{ plan.content.name }}</span>
            </h3>
          <span class="price-value price-value--mini price-value--light ">{{ plan.content.limit_label }}</span>
        </template>
        <template slot="image">
            <img src="img/vector/bike.svg" alt="Sponsor agreement">
        </template>
        <template slot="raw-content" v-if="plan.content.yearly_rate">
          <p class="price-value">
            {{ plan.content.yearly_rate }}€<span class="price-value__period">/año*</span>
          </p>
          <slot name="hint"></slot>
        </template>
        <template slot="raw-content" v-else>
          <p class="price-value price-value--medium mb-4 mt-4">
            Hablemos<span class="price-value__period"></span>
          </p>
          <slot name="hint"></slot>
        </template>
        <template slot="footer">
          <slot name="bottom"></slot>
        </template>
    </card>
</template>

<script>
    import Card from "../../components/Cards/Card";

    export default {
        name: "PricingSectionTest",
        components: {
          Card
        },
        props: [ 'plan' ]
    }
</script>
