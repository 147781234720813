<template>
  <ul v-if="!columns">
    <li
      v-for="(feature, index) in features"
      :key="index"
    >
      <i :class="setFeatureClass(index)"></i>
      <span>{{ feature }}</span>
    </li>
  </ul>
  <div v-else class="d-flex">
    <ul class="flex-basis-50 pl-5">
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <li
        v-for="(feature, index) in features"
        :key="index"
        v-if="index < (features.length / 2)"
        class="pl-5"
      >
        <i :class="setFeatureClass(index)" />
        <span>{{ feature }}</span>
      </li>
    </ul>
    <ul class="flex-basis-50 pl-3">
      <li
        v-for="(feature, index) in features"
        :key="index"
        v-if="index >= (features.length / 2)"
        class="pl-3"
      >
        <i class="stl-icon stl-icon-medium stl-icon--check mr-2"></i>
        <span>{{ feature }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PricingFeaturesAdvanced",
  props: {
    columns: { type: Boolean, default: false}
  },
  data () {
    return {
      features: [
          'Pack INICIO',
          'Carnet digital con QR',
          'Cobro automático de cuotas',
          `Inscripciones deportistas y socios`,
          `Mensajería con socios y tutores`,
          'Control de accesos y trazabilidad',
          'Calendario y horarios',
          'Gestión deportiva entrenadores',
          'Contabilidad y gestión',
          'Soporte por chat y mail',
      ]
    }
  },
  methods: {
    setFeatureClass(index) {
      return index === 0
        ? 'stl-icon stl-icon-medium stl-icon--plus mr-2'
        : 'stl-icon stl-icon-medium stl-icon--check mr-2'
    }
  }
}
</script>

<style lang="scss" scoped>
  ul li {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 8px 16px;
  }
</style>
