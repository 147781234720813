<script>
import SpinnerLoader from '../../components/SpinnerLoader.vue';
import StoryblokClient from "storyblok-js-client";

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default {
  data(){
    return {
      federation: {},
      loading: true,
    }
  },
  components: {
    SpinnerLoader,
  },
  name: 'SurfLicensesPortal',
  bodyClass: 'profile-page',
  mounted () {
    this.fetchFederation()
      .then((data) => {
        this.federation = data;
        this.loading = false;
    })
  },
  methods: {
    async fetchFederation() {
      const federations = await api.get('cdn/stories', {
        starts_with: 'surfcollection',
        content_type: 'federationCollection',
        filter_query: {
          id: { in: this.$route.params.federationId}
        },
        per_page: 1
      })

      return federations.data.stories[0];
    }
  }
}

</script>
<template>
  <div class="container d-flex flex-column align-items-center my-5">
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="container d-flex flex-column align-items-center" v-else>
      <div class="h4 mt-2 w-100"><router-link :to="{ name: 'surf-portal-federations'}">Inicio</router-link> / {{ federation.content.name }}</div>
      <h1 class="font-weight-bold">Licencias Surf Temporada 2024</h1>
      <h4 class="font-weight-bold mt-3">Selecciona como quieres realizar tu licencia</h4>

      <div class="row justify-content-center align-items-stretch my-3">
        <div class="mr-md-3 my-3 col-md-4 d-flex align-items-center flex-column">
          <router-link
            class="d-flex flex-column align-items-center"
            :to="{ name: 'surf-portal-clubs' }"
          >
            <div class="card-style text-dark">
              <span class="text-capitalize font-weight-bold px-2">Club</span>
              <img :src="require('/public/img/logos/shield_dark.png')" class="card-style__icon"> 
            </div>
            <span class="font-weight-bold text-dark mt-3">Pertenezco a un club</span>
          </router-link>
        </div>
        <div class="ml-md-3 my-3 col-md-4 d-flex align-items-center flex-column">
          <a
            class="d-flex flex-column align-items-center h-100"
            :href="federation.content.licenses_url"
          >
            <div class="card-style text-dark h-100 d-flex flex-column justify-content-start">
              <span class="text-capitalize font-weight-bold px-2">Individual</span>
              <img 
                :src="require('/public/img/logos/surf.png')" 
                class="card-style__icon justify-self-center"
              >
            </div>
            <span class="font-weight-bold text-dark mt-3">No pertenezco a un club</span>
          </a>
        </div>
      </div>
    </div>

    <p class="font-weight-bold mt-4">¿Alguna duda?</p>
    <ul class="pl-0">
      <li>Correo electrónico: <a href="mailto:administracion@fesurf.es">administracion@fesurf.es</a></li>
      <li>Teléfono: <a href="tel:+34981311666">981 311 666</a></li>
    </ul>

  </div>
</template>
<style scoped lang="scss">

.card-style {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid black;
    padding: 1.2rem;
    min-width: 120px;
    max-height: 20rem;
    text-align: center;
    animation: all 0.5s linear;
    background-color: white;
    &:hover {
    }
    &.selected {
      &:hover {
        opacity: .6;
      }
    }
    &:active {
      border: 0;
    }
    &.disabled {
      opacity: .2;
      pointer-events: none;
    }
    * {
      display: block;
    }
    &__icon {
      margin: 2rem;
      color: black;
    }
  }

  .title {
    .card-pay-method__icon {
      width: 25px;
    }
  }

  .c-tooltip {
    min-width: 300px;
  }

</style>