<template>
  <div class="container">
    <div class="mt-5 right-aligned brand">
      <h1 class="h1-seo mb-4 text-md-right text-center">{{ homeHeader.title }}</h1>
      <div class="d-flex justify-between features-wrapper col-12 col-lg-6">
        <div
          v-for="item in features"
          :key="item.title"
          class="feat-container"
        >
          <span :class="[
            'feat-block d-flex items-align-center bg-primary m-auto rounded',
            item.icon
            ]"
          >
            <img class="mx-auto d-block col-12" :src="`img/homepage/${item.icon}.svg`" alt="non payment" />
          </span>
          <p class="h2 title mb-0 feat-block__subtitle">{{ item.title }}</p>
          <p class="text-bold feat-block__subtitle">{{ item.subtitle }}</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-center features-wrapper col-12 col-lg-6"
      >
        <a
          href="https://app.cluber.es/register"
          class="btn btn-lg btn-secondary btn-round"
          size="xl"
        >
            {{ homeHeader.button_cta }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderSection',
    props: {
      homeHeader: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        features: [
          {
            title: '0%',
            subtitle: 'Impagos',
            icon: 'feat-non-payment',
          },
          {
            title: '0€',
            subtitle: 'comisiones bancarias',
            icon: 'feat-no-fees',
          },
          {
            title: 'APP',
            subtitle: 'Profesional',
            icon: 'feat-app',
          },
        ],
      }
    },
  }
</script>

<style scoped>
.feat-container {
  flex-basis: 33%;
}
.feat-block {
  flex-basis: 33%;
  width: 6rem;
  height: 6rem;
}

.feat-block__subtitle {
  color: #092643;
}
</style>
